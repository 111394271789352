import { VIDEO_TYPE_VIMEO } from 'constants/constants';
import { isNil } from 'utils/function';
import { vimeoPlayer } from './players/vimeoPlayer';

export const getVideoPlayer = ({ embedRef, videoType, videoTag, seconds }) => {
  if (!embedRef || !videoType || !videoTag) {
    return null;
  }

  if (videoType === VIDEO_TYPE_VIMEO) {
    return vimeoPlayer({ embedRef, videoTag, seconds });
  }

  return null;
};

export const getCurrentPlaylistIndex = (video, playlist = []) => {
  const currentIndex = playlist.findIndex(
    (playlistItem) => playlistItem.videoTag === video.videoTag
  );
  return currentIndex < 0 ? null : currentIndex;
};

export const getNextPlaylistItem = (video, playlist = []) => {
  const currentIndex = getCurrentPlaylistIndex(video, playlist);
  if (isNil(currentIndex)) {
    return null;
  }
  return playlist[currentIndex < playlist.length - 1 ? currentIndex + 1 : 0];
};

export const getPreviousPlaylistItem = (video, playlist = []) => {
  const currentIndex = getCurrentPlaylistIndex(video, playlist);
  if (isNil(currentIndex)) {
    return null;
  }
  return playlist[currentIndex === 0 ? playlist.length - 1 : currentIndex - 1];
};

export const isFirstInPlaylist = (video, playlist = []) => {
  const currentIndex = playlist.findIndex(
    (playlistItem) => playlistItem.videoTag === video.videoTag
  );
  return currentIndex === 0;
};

export const isLastInPlaylist = (video, playlist = []) => {
  const currentIndex = playlist.findIndex(
    (playlistItem) => playlistItem.videoTag === video.videoTag
  );
  return currentIndex === playlist.length - 1;
};
