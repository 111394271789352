import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  cssThemeColor,
  pxToRem,
} from 'utils/theme';
import { BORDER_RADIUS, BORDER_WIDTH } from 'constants/constants';
import { YellowSticker, RedSticker } from 'components/InfoStyles/InfoStyles';

const Container = styled(Link)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;

  ${({ $aspectRatio }) => {
    if ($aspectRatio) {
      return css`
        padding-top: ${$aspectRatio}%;
      `;
    }
    return null;
  }}

  ${({ $expanded }) => {
    if ($expanded) {
      return css`
        border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
      `;
    }
    return css`
      box-shadow: 0 12px 8px -8px black;
      border-radius: ${BORDER_RADIUS}px;
    `;
  }}

  ${({ $showDetailsOnHover }) => {
    if ($showDetailsOnHover) {
      return css`
        &:focus,
        &:hover {
          background: linear-gradient(
              rgba(0, 0, 0, 0.1),
              rgba(0, 0, 0, 0.3),
              rgba(0, 0, 0, 0.6)
            ),
            linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));

          ${ContentContainer} {
            opacity: 1;
          }

          ${Content} {
            bottom: 0;
          }

          ${RedSticker} {
            display: none;
          }

          ${YellowSticker} {
            display: block;
          }

          ${Image} {
            transform: scale(1.05);
            filter: sepia(1);
          }
        }
      `;
    }
    return null;
  }}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  transition: all 150ms ease-in-out;

  ${({ $aspectRatio }) => {
    if ($aspectRatio) {
      return css`
        position: absolute;
        inset: 0;
      `;
    }
    return null;
  }}
`;

const ContentContainer = styled.div`
  opacity: 0;
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.5) 70%,
    rgba(0, 0, 0, 0.5) 100%
  );
  border: ${BORDER_WIDTH}px solid ${cssThemeColor('hopeYellow')};
  border-radius: ${BORDER_RADIUS}px;
`;

const Content = styled.div`
  position: absolute;
  inset: 0;
  padding: ${pxToRem(12)};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${pxToRem(6)};
  transition: opacity 150ms ease-in-out;
  transition: bottom 300ms ease-out;
  bottom: -100%;
`;

const Title = styled.p`
  font-size: ${pxToRem(12)};
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

const SubTitle = styled.p`
  font-size: ${pxToRem(10)};
  font-weight: 500;
  margin: 0;
`;

const Info = styled.p`
  font-size: ${pxToRem(10)};
  margin: 0;
`;

const Copyright = styled.p`
  font-size: ${pxToRem(9)};
  font-weight: normal;
  margin: 0;
`;

export function CardImage({
  expanded,
  url,
  imageUrl,
  title,
  subtitle,
  copyright,
  info,
  sticker,
  showDetailsOnHover,
  aspectRatio,
  isLazy
}) {
  return (
    <Container
      to={`${url}`}
      $expanded={expanded}
      $showDetailsOnHover={showDetailsOnHover}
      $aspectRatio={aspectRatio}
    >
      {isLazy && (
        <Image
          $aspectRatio={aspectRatio}
          loading="lazy"
          src={`${imageUrl}.webp`}
          alt={title}
        />)
      }
      {!isLazy && (
        <Image
          $aspectRatio={aspectRatio}
          src={`${imageUrl}.webp`}
          alt={title}
        />)
      }
      {sticker && <RedSticker>{sticker}</RedSticker>}
      {showDetailsOnHover && (
        <ContentContainer>
          <Content>
            <Title>{title}</Title>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
            {info && <Info>{info}</Info>}
            {copyright && <Copyright>{copyright}</Copyright>}
          </Content>
          <YellowSticker>Katso</YellowSticker>
        </ContentContainer>
      )}
    </Container>
  );
}

CardImage.propTypes = {
  expanded: PropTypes.bool,
  showDetailsOnHover: PropTypes.bool,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  info: PropTypes.string,
  sticker: PropTypes.string,
  aspectRatio: PropTypes.number,
  isLazy: PropTypes.bool
};
