import React from 'react';
import styled from 'styled-components';

import { useCookieConsent } from 'components/CookieConsent/hooks';
import { PageWrapper } from 'components/Wrapper/PageWrapper';
import { ExpandingLink } from 'components/ExpandingLink/ExpandingLink';
import { useAppData } from 'hooks/useAppData';
import { historyUrl } from 'root/urls';

import { useViewingHistory } from 'hooks/useViewingHistory';
import { useVideoPlayer } from 'hooks/useVideoPlayer';
import { VideoPlayerCard } from 'components/VideoPlayerCard/VideoPlayerCard';
import { CARD_WIDTH } from 'constants/constants';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${CARD_WIDTH}px, 1fr));
  gap: 32px 16px;
`;

export const ViewingHistory = ({limit, noLink = false}) => {
  const { consentOptions } = useCookieConsent();
  const { getAugmentedViewingHistory } = useViewingHistory();
  const { open } = useVideoPlayer();
  const { allLabels } = useAppData();
  const videos = getAugmentedViewingHistory(limit) ?? [];

  if (videos.length === 0) {
    return null;
  }

  return (
    <PageWrapper>
      <ExpandingLink
          title="Viimeksi katsotut"
          linkTo={historyUrl}
          linkText={allLabels.labelExpandingLinkShowAll}
          inactive={noLink}
        />
      <Grid>
        {videos.map(({ video, path, playlist = [], seconds, duration }) => {
          const [type] = path;
          return (
            <VideoPlayerCard
              key={video.id}
              {...video}
              imageUrl={
                type === 'movie' ? video.heroImageUrlPosterMini : video.imageUrl
              }
              consentOptions={consentOptions ?? {}}
              onPlay={() => open({ video, path, playlist })}
              percentPlayed={(seconds / duration) * 100}
              titleOnly
              isLazy={false}
            />
          );
        })}
      </Grid>
    </PageWrapper>
  );
}

ViewingHistory.propTypes = {};
