import useEmblaCarousel from 'embla-carousel-react';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { media } from 'utils/media';
import { cssThemeColor, pxToRem } from 'utils/theme';
import { CONTENT_AREA_MAXWIDTH, SCROLL_BUTTON_WIDTH, HORIZONTAL_GAP } from 'constants/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 ${pxToRem(24)};
  max-width: ${pxToRem(CONTENT_AREA_MAXWIDTH + SCROLL_BUTTON_WIDTH * 2)};

  @media ${media.sm} {
    padding: 0 ${pxToRem(40)};
  }

  @media ${media.xl} {
    padding: 0;
  }
`;

const Fade = styled.div`
  z-index: 1;
  position: absolute;
  width: ${pxToRem(24)};
  height: 100%;

  ${({ $dir }) => {
    if ($dir === 'left') {
      return css`
        background: linear-gradient(
          90deg,
          rgba(34, 34, 34, 1) 0%,
          rgba(34, 34, 34, 0) 100%
        );
        left: 0;
      `;
    }
    return css`
      background: linear-gradient(
        90deg,
        rgba(34, 34, 34, 0) 0%,
        rgba(34, 34, 34, 1) 100%
      );
      right: 0;
    `;
  }}

  @media ${media.sm} {
    display: none;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: ${pxToRem(CONTENT_AREA_MAXWIDTH - SCROLL_BUTTON_WIDTH * 2)};
  position: relative;
`;

const ScrollerViewport = styled.div`
  width: 100%;

  @media ${media.sm} {
    overflow: hidden;
  }
`;

const GoToPage = styled.button`
  border: none;
  background: none;
  padding: 0;
  width: ${pxToRem(SCROLL_BUTTON_WIDTH)};
  height: 100%;
  cursor: pointer;
  color: ${cssThemeColor('hopeYellow')};
  position: absolute;
  top: 0;
  z-index: 10;
  display: flex;
  justify-items: center;
  align-items: center;
  display: none;

  &:disabled {
    cursor: default;
    color: ${cssThemeColor('darkGrey30')};
  }

  @media ${media.sm} {
    display: block;
  }

  ${({ $dir }) => {
    if ($dir === 'prev') {
      return css`
        left: ${pxToRem(-SCROLL_BUTTON_WIDTH)};
      `;
    }
    return css`
      right: ${pxToRem(-SCROLL_BUTTON_WIDTH)};
    `;
  }}

  &:disabled {
    color: ${cssThemeColor('lightGrey80')};
    cursor: default;
  }

  svg {
    width: ${pxToRem(SCROLL_BUTTON_WIDTH)};
    height: ${pxToRem(SCROLL_BUTTON_WIDTH)};
    flex-shrink: 0;
  }
`;

const Track = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 ${pxToRem(HORIZONTAL_GAP / -2)};
`;

const TrackItem = styled.li`
  padding: 0 ${pxToRem(HORIZONTAL_GAP / 2)};
  flex: 0 0 100%;
  min-width: 0;

  @media ${media.xsm} {
    flex: 0 0 50%;
  }
  @media ${media.sm} {
    flex: 0 0 33.33333%;
  }
  @media ${media.md} {
    flex: 0 0 25%;
  }
  @media ${media.lg} {
    flex: 0 0 20%;
  }
`;

export function ScrollList({ items, renderItem }) {
  const trackRef = useRef(null);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    speed: 15,
    breakpoints: {
      [media.sm]: {
        slidesToScroll: 2,
      },
      [media.md]: {
        slidesToScroll: 3,
      },
      [media.lg]: {
        slidesToScroll: 4,
      },
    },
  });

  const [, setSlidesNotInView] = useState([]);

  const onSettle = useCallback(
    () => setSlidesNotInView(emblaApi.slidesNotInView()),
    [emblaApi]
  );

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('resize', onSettle);
      emblaApi.on('settle', onSettle);
      onSettle();
    }
    return () => {
      emblaApi?.off('resize', onSettle);
      emblaApi?.off('settle', onSettle);
    };
  }, [emblaApi, onSettle]);

  const prev = useCallback(() => {
    setSlidesNotInView([]);
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const next = useCallback(() => {
    setSlidesNotInView([]);
    emblaApi?.scrollNext();
  }, [emblaApi]);

  return (
    <Container>
      <Fade $dir="left" />
      <InnerContainer>
        <ScrollerViewport ref={emblaRef}>
          <Track ref={trackRef}>
            {items.map((item, index) => (
              <TrackItem key={item.id}>{renderItem(item, index)}</TrackItem>
            ))}
          </Track>
        </ScrollerViewport>
        <GoToPage
          $dir="prev"
          onClick={prev}
          disabled={!emblaApi?.canScrollPrev()}
        >
          <ChevronLeftIcon />
        </GoToPage>
        <GoToPage
          $dir="next"
          onClick={next}
          disabled={!emblaApi?.canScrollNext()}
        >
          <ChevronRightIcon />
        </GoToPage>
      </InnerContainer>
      <Fade $dir="right" />
    </Container>
  );
}

ScrollList.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      imageUrl: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      sticker: PropTypes.string,
      duration: PropTypes.string,
      episodes: PropTypes.string,
      produced: PropTypes.string,
      suitableFor: PropTypes.string,
    })
  ),
};
