import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { cssThemeColor, cssThemeTypography } from 'utils/theme';

const InputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  font-family: ${cssThemeTypography('body')};
  font-size: 14px;
  line-height: 18px;

  ${({ $iconPlacement }) => {
    if ($iconPlacement === 'left') {
      return css`
        padding: 8px 8px 8px 36px;
      `;
    }
    if ($iconPlacement === 'right') {
      return css`
        padding: 8px 36px 8px 8px;
      `;
    }
    return css`
      padding: 8px;
    `;
  }}

  border: 1px solid ${cssThemeColor('white50')};
  border-radius: 4px;
  background: ${cssThemeColor('darkGrey30')};
  color: ${cssThemeColor('white')};

  &::placeholder {
    font-style: italic;
    color: ${cssThemeColor('white70')};
  }

  &:active,
  &:focus {
    outline-color: transparent;
    box-shadow: 0 0 0 2px ${cssThemeColor('hopeYellow50')};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  top: 0;
  left: ${({ $iconPlacement }) => ($iconPlacement === 'left' ? 0 : 'auto')};
  right: ${({ $iconPlacement }) => ($iconPlacement === 'right' ? 0 : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Input = ({
  type = 'text',
  icon,
  iconPlacement = 'left',
  ...rest
}) => (
  <InputContainer>
    {icon && iconPlacement === 'left' && (
      <IconContainer $iconPlacement={iconPlacement}>{icon}</IconContainer>
    )}
    <StyledInput {...rest} type={type} $iconPlacement={iconPlacement} />
    {icon && iconPlacement === 'right' && (
      <IconContainer $iconPlacement={iconPlacement}>{icon}</IconContainer>
    )}
  </InputContainer>
);

Input.propTypes = {
  icon: PropTypes.element,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
};
