import { AnimatePresence, motion } from 'framer-motion';
import { useMatchMedia } from 'hooks/useMatchMedia';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeColor } from 'utils/theme';

const modalVariantConfig = {
  fixedWide: '640px',
  fluidExtraWide: '80%',
};

const MotionOverlay = motion.div;
const MotionContent = motion.article;

const Overlay = styled(MotionOverlay)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;

  @media ${media.md} {
    background: ${cssThemeColor('white30')};
  }
`;

const Content = styled(MotionContent)`
  width: 100%;
  height: 100%;
  background: ${cssThemeColor('mediumGrey')};
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${media.md} {
    height: auto;
    border-radius: 8px;
    min-width: ${({ $variant }) => modalVariantConfig[$variant]};
    max-width: ${({ $variant }) => modalVariantConfig[$variant]};
  }
`;

const getModalAnimation = (isMdMedia) => {
  if (isMdMedia) {
    return {
      initial: { opacity: 0.5, scale: 0.5 },
      animate: { opacity: 1, scale: 1 },
      exit: { scale: 0, transition: { duration: 0.15 } },
    };
  }
  return {
    initial: { y: '-50%' },
    animate: { y: '0%' },
    exit: { y: '-100%' },
  };
};

export const Modal = ({
  isVisible,
  variant = 'medium',
  children,
  onDismiss,
}) => {
  const isMdMedia = useMatchMedia(media.md);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onDismiss?.();
      }
    },
    [onDismiss]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.body.style.position = isVisible ? 'fixed' : 'relative';
  }, [isVisible]);

  const { initial, animate, exit } = getModalAnimation(isMdMedia);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isVisible && (
        <>
          <Overlay
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            onClick={onDismiss}
          >
            <Content
              initial={initial}
              animate={animate}
              exit={exit}
              $variant={variant}
              onClick={(event) => event.stopPropagation()}
            >
              {children}
            </Content>
          </Overlay>
        </>
      )}
    </AnimatePresence>,
    document.body
  );
};

Modal.propTypes = {
  isVisible: PropTypes.bool,
  variant: PropTypes.oneOf(['fixedWide', 'fluidExtraWide']),
  onDismiss: PropTypes.func,
};
