export function sortEpisodes(sort = 'episode', dir = 'asc') {
  if (sort === 'title' && dir === 'asc') {
    return sortByTitleAscFn;
  }
  if (sort === 'title' && dir === 'desc') {
    return sortByTitleDescFn;
  }
  if (sort === 'reference') {
    return sortByReferenceFn;
  }
  if (sort === 'episode' && dir === 'desc') {
    return sortDescendingFn;
  }
  return sortAscendingFn;
}

export function sortByTitleAscFn(a, b) {
  let x = a.title.toLowerCase();
  let y = b.title.toLowerCase();
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
}

export function sortByTitleDescFn(a, b) {
  let x = a.title.toLowerCase();
  let y = b.title.toLowerCase();
  if (x < y) {
    return 1;
  }
  if (x > y) {
    return -1;
  }
  return 0;
}

export function sortByReferenceFn(a, b) {
  let x = 99999999;
  if (a?.refBook) x = a.refBook * 1000000;
  if (a?.refChapter) x = x + a.refChapter * 1000;
  if (a?.refVerse) x = x + a.refVerse;

  let y = 99999999;
  if (b?.refBook) y = b.refBook * 1000000;
  if (b?.refChapter) y = y + b.refChapter * 1000;
  if (b?.refVerse) y = y + b.refVerse;

  return x - y;
}

export function sortAscendingFn(a, b) {
  return a.episodeNumber - b.episodeNumber;
}

export function sortDescendingFn(a, b) {
  return b.episodeNumber - a.episodeNumber;
}

export function sortByStickerFn(a, b) {
   if (isReleasedDateFuture(a.releasedAt)) {
      return -1;
   }
   if (isReleasedDateFuture(b.releasedAt)) {
      return 1;
   }
   if (isReleasedDateStillValid(a.updatedAt)) {
     return -1;
   }
   if (isReleasedDateStillValid(b.updatedAt)) {
     return 1;
   }
   return sortByTitleAscFn(a, b);
}

export function sortByDateFn(a, b) {
  let x = a.updatedAt;
  let y = b.updatedAt;
  if (x < y) {
    return 1;
  }
  if (x > y) {
    return -1;
  }
  return 0;
}

export function findAllGenreMatches(series, allSeries) {
  let list = [];
  for (let sidx = 0; sidx < allSeries.length; sidx++) {
    for (let idx = 0; idx < series.genre.length; idx++) {
      if (allSeries[sidx].genre.includes(series.genre[idx])) list.push(allSeries[sidx]);
    }
  }
  return list;
}

export function removeDuplicatesById(data) {
  const ids = data.map(o => o.id);
  return data.filter(({ id }, index) => !ids.includes(id, index + 1));
}

export function removeDuplicateGenreMatches(self, data) {
  data = data.filter(o => o.id !== self);
  return removeDuplicatesById(data);
}

export function reduceGenreListSize(found) {
  while (found.length > 4) {
    let r = found[Math.floor(Math.random() * found.length)].id;
    found = found.filter(o => o.id !== r);
  }
  return found;
}

export function forChildren(item) {
  return item.forChildren;
}

export function notForChildren(item) {
  return !item.forChildren;
}

export const genreFilter = (genre) => (item) => {
  if (genre) {
    return item.genre.includes(genre);
  }
  return true;
};

export const isReleasedDateStillValid = (inputDate) => {
  const timeComp = Date.parse(new Date(inputDate)) + 1209600000;
  const timeNow = Date.now();
  return timeNow < timeComp;
}

export const isReleasedDateFuture = (inputDate) => {
   const timeComp = Date.parse(new Date(inputDate));
   const timeNow = Date.now();
   return timeNow < timeComp;
 }

export const getStickerText = (releasedAt, updatedAt, id) => {
   const TWOWEEKS = 1209600000;
   const releasedDate = Date.parse(new Date(releasedAt));
   const updatedDate = Date.parse(new Date(updatedAt));
   const timeNow = Date.now();

   if ( releasedDate > timeNow ) return "tulossa";
   if ( releasedDate === updatedDate ) {
      if ( timeNow < (releasedDate + TWOWEEKS)) {
         if ( id.includes ("MOVIE") ) return "uutuus";
         return "uusi sarja";
      }
      return null;
   }
   if ( timeNow < (updatedDate + TWOWEEKS )) return "uusi jakso";
   return null;
}