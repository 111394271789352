import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeSemanticColor } from 'utils/theme';

const justifyContentMap = {
  start: 'flex-start',
  end: 'flex-end',
  between: 'space-between',
};

const Container = styled.footer`
  padding: 16px;
  border-top: 1px solid ${cssThemeSemanticColor('border')};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContentMap[justifyContent]};
  align-items: center;
  gap: 16px;

  @media ${media.md} {
    padding: 24px;
  }
`;

export const ModalFooter = ({ justifyContent = 'end', children }) => {
  return <Container justifyContent={justifyContent}>{children}</Container>;
};

ModalFooter.propTypes = {
  justifyContent: PropTypes.oneOf(['start', 'end', 'between']),
};
