import { CookieConsent } from 'components/CookieConsent/CookieConsent';
import { Footer } from 'components/Footer/Footer';
import { Navigation } from 'components/Navigation/Navigation';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { PAGE_IMAGE_ID } from 'constants/constants';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { breakpoints, media } from 'utils/media';
import { cssThemeColor, pxToRem } from 'utils/theme';
import { BrowserCompatibility } from '../BrowserCompatibility/BrowserCompatibility';

const fluidPageImageSize = (minHeight, maxHeight, nextBreakpoint) => {
  return css`
    height: ${minHeight}px;
    height: clamp(
      ${pxToRem(minHeight)},
      ${(maxHeight / nextBreakpoint) * 100}vw,
      ${pxToRem(maxHeight)}
    );
  `;
};

const PageImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 680px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media ${media.sm} and (orientation: landscape) {
    ${fluidPageImageSize(432, 784, breakpoints.md)}
  }

  @media ${media.md} {
    ${fluidPageImageSize(432, 784, breakpoints.lg)}
  }

  @media ${media.lg} {
    height: 784px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        rgba(0, 0, 0, 0),
        ${cssThemeColor('darkGrey15')},
        ${cssThemeColor('darkGrey')}
      ),
      linear-gradient(
        to left,
        rgba(0, 0, 0, 0),
        ${cssThemeColor('darkGrey30')},
        ${cssThemeColor('darkGrey95')}
      );
  }
`;

const Content = styled.main`
  position: relative;
  flex: 1;
  padding-bottom: 96px;
`;

export const Layout = () => {
  return (
    <>
      <PageImageContainer id={PAGE_IMAGE_ID} />
      <BrowserCompatibility />
      <Navigation />
      <Content>
        <Outlet />
      </Content>
      <Footer />
      <CookieConsent />
      <VideoPlayer />
    </>
  );
};
