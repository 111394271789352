import { useState, useEffect } from 'react';

export const useMatchMedia = (mediaQuery) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (!window || !window.matchMedia) {
      return;
    }

    const mediaQueryList = window.matchMedia(mediaQuery);

    const listener = (event) => {
      setMatches(event.matches);
    };

    if (mediaQueryList) {
      setMatches(mediaQueryList?.matches);
      if (!mediaQueryList.addEventListener && mediaQueryList.addListener) {
        mediaQueryList.addListener(listener);
      } else {
        mediaQueryList.addEventListener('change', listener);
      }

      return () => {
        if (
          !mediaQueryList.removeEventListener &&
          mediaQueryList.removeListener
        ) {
          mediaQueryList.removeListener(listener);
        } else {
          mediaQueryList.removeEventListener('change', listener);
        }
      };
    }
  }, [mediaQuery]);

  return matches;
};
