import { ReactComponent as HeartIcon } from 'assets/icons/heart-regular.svg';
import { ReactComponent as HeartSolidIcon } from 'assets/icons/heart-solid.svg';
import { Button } from 'components/Button/Button';
import { IconButton } from 'components/IconButton/IconButton';
import { Tag } from 'components/Tag/Tag';
import { motion } from 'framer-motion';
import { useFavourites } from 'hooks/useFavourites';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  cssThemeColor,
  cssThemeSemanticColor,
  cssThemeTypography,
  pxToRem,
} from 'utils/theme';
import { CardImage } from './CardImage';
import { BORDER_RADIUS } from 'constants/constants';

const Container = styled.article`
  background: ${cssThemeColor('mediumGrey')};
  border-radius: ${BORDER_RADIUS}px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
  position: relative;
`;

const ExpandedCardFooter = styled.footer`
  padding: ${pxToRem(12)};
`;

const CardFooterButtons = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
`;

const FavouritesButton = styled(IconButton)`
  color: ${cssThemeColor('hopeYellow')};
`;

const MotionExpandedCardFooter = motion(ExpandedCardFooter);

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  font-size: ${pxToRem(10)};
  margin-top: ${pxToRem(16)};
`;

const Title = styled.h4`
  font-size: ${pxToRem(12)};
  font-weight: normal;
  margin: 0;
`;

const Subtitle = styled.p`
  margin: 0;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(8)};
`;

const VerticalLine = styled.div`
  width: 1px;
  align-self: stretch;
  background: ${cssThemeSemanticColor('border')};
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const Keywords = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0 ${pxToRem(8)};
`;

const Keyword = styled.li`
  text-transform: lowercase;
  font-variant: small-caps;
  font-size: ${pxToRem(14)};
  font-family: ${cssThemeTypography('keyword')};
  color: ${cssThemeColor('white70')};
`;

export const ExpandedCard = ({
  id,
  url,
  imageUrl,
  title,
  subtitle,
  sticker,
  info,
  suitableFor,
  videoFormat,
  keywords = [],
  ref,
  isLazy
}) => {
  const { addToFavourites, removeFromFavourites, isFavourite } =
    useFavourites();

  const isVideoFavourite = useMemo(() => isFavourite(id), [id, isFavourite]);

  return (
    <Container ref={ref}>
      <CardImage
        url={url}
        imageUrl={imageUrl}
        title={title}
        sticker={sticker}
        expanded={true}
        isLazy={isLazy}
      />
      <MotionExpandedCardFooter
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <CardFooterButtons>
          <Button as={Link} to={url} size="tiny" iconPlacement="right">
            Katso
          </Button>
          <FavouritesButton
            icon={isVideoFavourite ? <HeartSolidIcon /> : <HeartIcon />}
            title={isVideoFavourite ? 'Poista suosikeista' : 'Lisää suosikiksi'}
            onClick={() =>
              isVideoFavourite
                ? removeFromFavourites(id)
                : addToFavourites({ id, url })
            }
          />
        </CardFooterButtons>
        <Details>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {keywords.length > 0 && (
            <Keywords>
              {keywords.map((kw, i) => (
                <Keyword key={`keyword-${i}`}>{kw}</Keyword>
              ))}
            </Keywords>
          )}
          <Info>
            {info && (
              <>
                <NoWrap>{info}</NoWrap> <VerticalLine />
              </>
            )}
            <Tag size="tiny" variant="outlineInfo" radius="none">
              {suitableFor}
            </Tag>
            <VerticalLine />
            <Tag size="tiny" variant="outlineInfo" radius="none">
              {videoFormat}
            </Tag>
          </Info>
        </Details>
      </MotionExpandedCardFooter>
    </Container>
  );
};

ExpandedCard.propTypes = {
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  info: PropTypes.node,
  sticker: PropTypes.string,
  suitableFor: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};
