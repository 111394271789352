import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { media } from 'utils/media';
import { cssThemeColor, cssThemeSemanticColor } from 'utils/theme';
import { ReactComponent as ErrorIcon } from 'assets/icons/x-circle.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/information-circle.svg';
import { ReactComponent as WarnIcon } from 'assets/icons/exclamation-triangle.svg';
import { pxToRem } from '../../utils/theme';

const iconVariant = {
  info: InfoIcon,
  warn: WarnIcon,
  error: ErrorIcon,
};

const Root = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  position: relative;

  ${({ $variant }) => {
    if ($variant === 'warn') {
      return css`
        background: ${cssThemeColor('hopeYellow')};
        color: ${cssThemeSemanticColor('textSecondary')};

        a {
          color: ${cssThemeSemanticColor('textSecondary')};
        }
      `;
    }
    if ($variant === 'error') {
      return css`
        background: ${cssThemeColor('hopeRed')};
        color: ${cssThemeSemanticColor('textPrimary')};

        a {
          color: ${cssThemeSemanticColor('textPrimary')};
        }
      `;
    }
    return css`
      background: ${cssThemeColor('hopeBlue')};
      color: ${cssThemeSemanticColor('textPrimary')};

      a {
        color: ${cssThemeSemanticColor('textPrimary')};
      }
    `;
  }};

  @media ${media.lg} {
    padding: 16px 24px;
  }

  a {
    font-weight: 700;
  }
`;

const IconContainer = styled.span`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  display: block;
  flex: 0 0 auto;
  margin-right: ${pxToRem(16)};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export function Notification({ variant = 'info', className, children }) {
  const Icon = iconVariant[variant];
  return (
    <Root $variant={variant} className={className}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <span>{children}</span>
    </Root>
  );
}

Notification.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['info', 'warn', 'error']),
};
