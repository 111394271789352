export const theme = {
  colors: {
    transparent: 'rgba(0, 0, 0, 0)',
    black: '#000000',
    white: '#ffffff',
    white80: 'rgba(255, 255, 255, 0.8)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white40: 'rgba(255, 255, 255, 0.4)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white20: 'rgba(255, 255, 255, 0.2)',
    white10: 'rgba(255, 255, 255, 0.1)',
    white5: 'rgba(255, 255, 255, 0.05)',
    faintGrey: '#cccccc',
    lightGrey: '#969696',
    lightGrey80: 'rgba(150, 150, 150, 0.8)',
    mediumGrey: '#2F2F2F',
    darkGrey: '#222222',
    darkGrey95: 'rgba(34, 34, 34, 0.95)',
    darkGrey80: 'rgba(34, 34, 34, 0.8)',
    darkGrey70: 'rgba(34, 34, 34, 0.7)',
    darkGrey50: 'rgba(34, 34, 34, 0.5)',
    darkGrey30: 'rgba(34, 34, 34, 0.3)',
    darkGrey15: 'rgba(34, 34, 34, 0.15)',
    hopeBlue: 'rgb(3, 78, 162)',
    hopeBlue70: 'rgb(3, 78, 162, 0.7)',
    hopeRed: '#b80000',
    hopeYellow: '#F4D331',
    hopeYellow90: 'rgba(244, 211, 49, 0.9)',
    hopeYellow80: 'rgba(244, 211, 49, 0.8)',
    hopeYellow50: 'rgba(244, 211, 49, 0.5)',
    hopeYellow30: 'rgba(244, 211, 49, 0.3)',
    hopeYellow10: 'rgba(244, 211, 49, 0.1)',
    hopeOrange: 'rgb(255, 210, 90)'
  },
  semanticColors: {
    textPrimary: '#ffffff',
    textSecondary: '#141313',
    border: 'rgba(255, 255, 255, 0.1)',
    shadow: 'rgba(0, 0, 0, 0.75)',
  },
  typography: {
    body: '"Open Sans", sans-serif',
    heading: '"Open Sans", sans-serif',
    heading_hero: '"Roboto Condensed", sans-serif',
    heading_uppercase: '"Bebas Neue", sans-serif',
    button: '"Roboto Condensed", sans-serif',
    keyword: '"Roboto Condensed", sans-serif',
  },
};
