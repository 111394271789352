import React, { useMemo } from 'react';
import { useAppData } from 'hooks/useAppData';
import { HomeHeroBlock } from './HomeHeroBlock';
import { MoviesPosterGrid } from 'views/Movies/MoviesPosterGrid';
import { ViewingHistory } from './ViewingHistory';
import { SeriesPosterGrid } from 'components/SeriesPosterGrid/SeriesPosterGrid';
import { useSeries } from '../../hooks/useSeries';
import { sortAscendingFn, sortByDateFn } from '../../utils/utils';
import { moviesUrl, seriesUrl } from 'root/urls';
import { CategoryTitle, TopicWithExpandingLink } from 'components/InfoStyles/InfoStyles';

const FindBasedOnDates = () => {
  return useSeries(() => true, () => true, {
    mini: false,
    sort: sortByDateFn,
  }).slice(0, 8);
}

const FindVideos = () => {
  return useSeries(() => true, () => true, {
    mini: true,
    sort: sortAscendingFn,
  })
}

export const Home = () => {
  const { allSeries, allMovies } = useAppData();

  const vBig = FindBasedOnDates();
  const videos = FindVideos();

  const v1 = useMemo(() => videos.filter(s => s.genre.includes("elämä") || s.genre.includes("musiikki") || s.genre.includes("todistuksia") || s.genre.includes("luonto")), [videos]);
  const v2 = useMemo(() => videos.filter(s => s.genre.includes("raamatun selitys")), [videos]);
  const v3 = useMemo(() => videos.filter(s => s.genre.includes("profetia")), [videos]);
  const v4 = useMemo(() => videos.filter(s => s.genre.includes("hengellisyys") || s.genre.includes("puheet")), [videos]);
  const v5 = useMemo(() => videos.filter(s => s.genre.includes("historia")), [videos]);

  return (
    <>
      <HomeHeroBlock allSeries={allSeries} allMovies={allMovies} />
      <ViewingHistory limit={4} />
      <TopicWithExpandingLink 
        topic={"Sarjoja"}
        topicUrl={seriesUrl}
      />
      <CategoryTitle 
        category={"Uusimmat"}
      />
      <SeriesPosterGrid
        videos={vBig}
        nogap
        scroll
      />
      <CategoryTitle 
        category={"Elävässä elämässä"}
      />
      <SeriesPosterGrid
         videos={v1}
         mini
         nogap
      />
      <CategoryTitle 
         category={"Raamatun parissa"}
      />
      <SeriesPosterGrid
         videos={v2}
         mini
         nogap
      />
      <CategoryTitle 
        category={"Maailma profetioiden valossa"}
      />
      <SeriesPosterGrid
         videos={v3}
         mini
         category={"Profetia"}
         nogap
      />
      <CategoryTitle 
         category={"Hengellisiä ajatuksia"}
      />
      <SeriesPosterGrid
         videos={v4}
         mini
         nogap
      />
      <CategoryTitle 
         category={"Historiaa ajasta ikuisuuteen"}
      />
      <SeriesPosterGrid
         videos={v5}
         mini
      />
      <TopicWithExpandingLink 
        topic={"Elokuvat ja Dokumentit"}
        topicUrl={moviesUrl}
      />
      <MoviesPosterGrid
         filter={() => true}
         sortBySticker
      />
    </>
  );
};
