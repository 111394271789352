import { useCallback, useContext } from 'react';
import { AppDataContext } from 'root/AppDataContext';

export const useAppData = () => {
  const { movies, series, books, labels } = useContext(AppDataContext);

  const moviesList = movies.movies;

  const getMovie = useCallback(
    (movieSlug) => {
      const singleMovie = moviesList.find((m) => m.slug === movieSlug);
      if (singleMovie) {
        return singleMovie;
      }
      return null;
    },
    [moviesList]
  );

  const getSeries = useCallback(
    (seriesSlug) => {
      const singleSeries = series.find((s) => s.slug === seriesSlug);
      if (singleSeries) {
        return singleSeries;
      }
      return null;
    },
    [series]
  );

  const getSeriesSeasonByCode = useCallback(
    (series, seasonSlug) => series.seasons.find((s) => s.slug === seasonSlug),
    []
  );

  const getSeasonByCode = useCallback(
    (seriesSlug, seasonSlug) => {
      const series = getSeries(seriesSlug);
      if (series) {
        return getSeriesSeasonByCode(series, seasonSlug);
      }
      return null;
    },
    [getSeries, getSeriesSeasonByCode]
  );

  const getSeriesByPath = useCallback(
    (path) => {
      const [type, seriesSlug] = path;

      if (type !== 'series') {
        return null;
      }

      return getSeries(seriesSlug);
    },
    [getSeries]
  );

  const getSeasonByPath = useCallback(
    (path) => {
      const [type, seriesSlug, seasonSlug] = path;

      if (type !== 'series') {
        return null;
      }

      return getSeasonByCode(seriesSlug, seasonSlug);
    },
    [getSeasonByCode]
  );

  const getEpisodeByPath = useCallback(
    (path) => {
      const [, , , episodeId] = path;

      const season = getSeasonByPath(path);

      if (season) {
        return season.episodes.find((e) => e.id === episodeId);
      }

      return null;
    },
    [getSeasonByPath]
  );

  const getBookGroup = useCallback(
    (bookGroup) => {
      const singleBookGroup = books.bookGroups.find(
        (b) => b.slug === bookGroup
      );
      if (singleBookGroup) {
        return singleBookGroup;
      }
      return null;
    },
    [books]
  );

  const getBooksByGroup = useCallback(
    (books, bookCode) => books.books.find((b) => b.slug === bookCode),
    []
  );

  const getSuggestedSeries = useCallback(
    (suggested, series) => {
      suggested = series = null;
      return [];
    }, []
  );

  return {
    allMovies: movies,
    allSeries: series,
    allBooks: books,
    allLabels: labels,
    getMovie,
    getSeries,
    getSeriesByPath,
    getSeriesSeasonByCode,
    getSeasonByCode,
    getSeasonByPath,
    getEpisodeByPath,
    getBookGroup,
    getBooksByGroup,
    getSuggestedSeries,
  };
};
