import styled from 'styled-components';
import { cssThemeColor, cssThemeTypography } from 'utils/theme';

const Title = styled.h2`
  color: ${cssThemeColor('hopeYellow')};
  font-family: ${cssThemeTypography('heading')};
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

export const Topic = ({ title, className }) => {
  return <Title className={className}>{title}</Title>;
};
