import { fontStyles } from './fontStyles';

const { createGlobalStyle } = require('styled-components');
const { cssThemeColor, cssThemeTypography } = require('utils/theme');

export const GlobalStyle = createGlobalStyle`
*, *::after, *::before {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

${fontStyles};

html,
body {
  width: 100%;
  height: 100%;    
  background: ${cssThemeColor('darkGrey')};
  color: ${cssThemeColor('white')};

  font-family: ${cssThemeTypography('body')};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {    
  margin: 0;
}

a {
  color: ${cssThemeColor('hopeYellow')};
  text-decoration: none;
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    outline-color: transparent;
  }
}

img {
  max-width: 100%
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
  shape-margin: 0.75rem;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
`;
