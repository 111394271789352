export const isNil = (value) => {
  return value === null || value === undefined;
};

export const isValue = (value) => {
  return !isNil(value);
};

export const debounce = (func, wait = 500) => {
  let timeout;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func.apply(this, arguments);
    }, wait);
  };
};
