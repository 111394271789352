export const cssThemeColor =
  (color) =>
  ({ theme }) =>
    theme.colors[color];

export const cssThemeSemanticColor =
  (semanticName) =>
  ({ theme }) =>
    theme.semanticColors[semanticName];

export const cssThemeTypography =
  (typography) =>
  ({ theme }) =>
    theme.typography[typography];

export const pxToRem = (px) => `${px / 16}rem`;
