import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AccordionItem } from './AccordionItem';

export const Accordion = ({ items, renderHeader, renderContent }) => {
  const [openItems, setOpenItems] = useState([]);

  const setOpen = (itemId) => {
    if (openItems.includes(itemId)) {
      setOpenItems((prevOpenItems) => {
        return prevOpenItems.filter((id) => id !== itemId);
      });
    } else {
      setOpenItems([...openItems, itemId]);
    }
  };

  return items.map((item) => (
    <AccordionItem
      key={item.id}
      item={item}
      open={openItems.includes(item.id)}
      setOpen={setOpen}
      renderHeader={renderHeader}
      renderContent={renderContent}
    />
  ));
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
};
