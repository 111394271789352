import PropTypes from 'prop-types';
import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';
import { omitProps } from 'utils/styledComponents';
import { navigationLinkBaseStyles } from './NavigationLink';

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: omitProps(['$isActive']),
})`
  ${navigationLinkBaseStyles}
  padding: 8px 0;
  width: 100%;
  display: block;
`;

export const MobileNavigationLink = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved?.pathname, end: true });

  return (
    <StyledLink to={to} $isActive={match} {...props}>
      {children}
    </StyledLink>
  );
};

MobileNavigationLink.propTypes = {
  to: PropTypes.string,
};
