import PosterGrid from 'components/PosterGrid/PosterGrid';
import PropTypes from 'prop-types';
import { useMovies } from './hooks/useMovies';
import { sortByTitleAscFn, sortByStickerFn } from 'utils/utils';

export function MoviesPosterGrid({
  mini,
  filter,
  filters,
  sortBySticker = false,
}) {
  let videos = useMovies(filter, {
    mini,
    sort: sortBySticker ? sortByStickerFn : sortByTitleAscFn,
  });

  return (
    <PosterGrid
      videos={videos}
      filters={filters}
      mini={mini}
      aspectRatio={100}
      isLazy={true}
    />
  );
}

MoviesPosterGrid.propTypes = {
  topic: PropTypes.string,
  topicUrl: PropTypes.string,
  topicLinkLabel: PropTypes.string,
  mini: PropTypes.bool,
  filter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  filters: PropTypes.node,
};
