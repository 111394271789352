import { useState, useEffect, useCallback } from 'react';

export const useCountdown = (seconds) => {
  const [intervalId, setIntervalId] = useState();
  const [countDown, setCountdown] = useState(seconds);

  const startCountdown = useCallback(() => {
    const interval = setInterval(() => {
      setCountdown((currentCountdown) => currentCountdown - 1);
    }, 1000);
    setIntervalId(interval);
  }, []);

  const resetCountdown = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setCountdown(seconds);
  }, [intervalId, seconds]);

  useEffect(() => {
    return () => resetCountdown();
  }, [resetCountdown]);

  useEffect(() => {
    if (countDown === 0) {
      clearInterval(intervalId);
    }
  }, [countDown, intervalId]);

  return { countDown, startCountdown, resetCountdown };
};
