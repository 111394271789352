import { Button } from 'components/Button/Button';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { privacyUrl } from 'root/urls';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeColor } from 'utils/theme';

const CookieBannerContainer = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${cssThemeColor('darkGrey')};
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${media.sm} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }

  @media ${media.md} {
    padding: 24px;
  }
`;

const Description = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;

const Actions = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${media.sm} {
    gap: 32px;
    justify-content: flex-end;
  }
`;

const MotionCookieBannerContainer = motion(CookieBannerContainer);

export const CookieBanner = ({ isVisible, onAccept, onShowOptions }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <MotionCookieBannerContainer
          initial={{ translateY: '100%' }}
          animate={{
            translateY: '0%',
            transition: { delay: 1 },
          }}
          exit={{ translateY: '100%', transition: { duration: 0.2 } }}
        >
          <Description>
            Käytämme evästeitä sivun teknisessä toteutuksessa ja
            personoidaksemme sisältöä sinua varten. Lisätietoja löydät{' '}
            <Link to={privacyUrl}>tietosuojaselosteesta</Link>.
          </Description>
          <Actions>
            <Button onClick={onShowOptions}>Muokkaa evästevalintoja</Button>
          </Actions>
        </MotionCookieBannerContainer>
      )}
    </AnimatePresence>
  );
};

CookieBanner.propTypes = {
  isVisible: PropTypes.bool,
  onAccept: PropTypes.func,
  onShowOptions: PropTypes.func,
};
