import { CookieConsentProvider } from 'components/CookieConsent/CookieConsentProvider';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AppDataContextProvider } from './AppDataContext';
import { GlobalStyle } from './GlobalStyle';
import { Routes } from './Routes';
import { theme } from './theme';
import { useFetchAppData } from './useFetchAppData';
import { VideoPlayerProvider } from '../components/VideoPlayer/VideoPlayerContext';
import { ScrollToTop } from '../components/ScrollToTop/ScrollToTop';

export const App = () => {
  const appData = useFetchAppData();

  const { isLoading, error } = appData;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CookieConsentProvider>
          <AppDataContextProvider value={appData}>
            <VideoPlayerProvider>
              <ScrollToTop />
              <GlobalStyle />
              <Routes isLoading={isLoading} error={error} />
            </VideoPlayerProvider>
          </AppDataContextProvider>
        </CookieConsentProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
