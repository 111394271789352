import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { cssThemeColor } from 'utils/theme';

const variantMap = {
  blue: css`
    background: ${cssThemeColor('hopeBlue')};
    color: ${cssThemeColor('white')};
  `,
  outlineWhite: css`
    background: transparent;
    border: 1px solid ${cssThemeColor('white')};
    color: ${cssThemeColor('white')};
  `,
  outlineFaintGrey: css`
    background: transparent;
    border: 1px solid ${cssThemeColor('faintGrey')};
    color: ${cssThemeColor('faintGrey')};
  `,
  outlineInfo: css`
    background: rgba(40, 40, 40, 1);
    border: 1px solid ${cssThemeColor('white')};
    color: ${cssThemeColor('white')};
  `,
};

const sizeMap = {
  tiny: css`
    font-size: 0.6rem;
    padding: 2px 4px;
    line-height: 0.75rem;
  `,
  small: css`
    font-size: 0.75rem;
    padding: 4px 8px;
    line-height: 0.75rem;
  `,
  medium: css`
    font-size: 0.875rem;
    padding: 4px 8px;
    line-height: 1rem;
  `,
  large: css`
    font-size: 1rem;
    padding: 4px 8px;
    line-height: 1rem;
    max-height: 1.6rem;
  `,
};

const radiusMap = {
  none: css`
    border-radius: 0;
  `,
  tiny: css`
    border-radius: 4px;
  `,
  small: css`
    border-radius: 6px;
  `,
  medium: css`
    border-radius: 8px;
  `,
  large: css`
    border-radius: 10px;
  `,
};

const TagContainer = styled.div`
  ${({ $variant }) => variantMap[$variant]}
  ${({ $radius }) => radiusMap[$radius]}
  ${({ $size }) => sizeMap[$size]}
  font-family: ${({ theme, $fontVariant }) => theme.typography[$fontVariant]};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
`;

export const Tag = ({
  className,
  size = 'medium',
  radius = 'medium',
  variant = 'blue',
  fontVariant = 'button',
  children,
}) => {
  return (
    <TagContainer
      className={className}
      $size={size}
      $radius={radius}
      $variant={variant}
      $fontVariant={fontVariant}
    >
      {children}
    </TagContainer>
  );
};

Tag.propTypes = {
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  radius: PropTypes.oneOf(['none', 'tiny', 'small', 'medium', 'large']),
  variant: PropTypes.oneOf([
    'blue',
    'outlineWhite',
    'outlineFaintGrey',
    'outlineInfo',
  ]),
  fontVariant: PropTypes.oneOf(['body', 'button']),
};
