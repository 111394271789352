import { useAppData } from 'hooks/useAppData';
import { useMemo } from 'react';
import { seriesUrl } from 'root/urls';
import { filterPredicates } from 'utils/array';

export function seasonToPosterVideo(series, season, mini, allLabels) {
  const {
    id,
    episodeCount,
    produced,
    suitableFor,
    videoFormat,
    releasedAt
  } = season;

  const updatedAt = season.episodes.map((v) => v.releasedAt).sort().reverse()[0];

  return {
    id,
    url: `${seriesUrl}/${series.slug}/${season.slug}`,
    imageUrl: mini ? season.heroImageUrlPosterMini : season.heroImageUrlPoster,
    title: series.title,
    subtitle: season.title,
    info: `${episodeCount} ${episodeCount > 1 ? allLabels.labelEpisodeCountMulti : allLabels.labelEpisodeCountSingle}`,
    produced,
    producer: series.producers?.[0],
    suitableFor,
    videoFormat,
    keywords: series.keywords,
    genre: series.genre,
    releasedAt: releasedAt,
    updatedAt: updatedAt
  };
}

export function useSeries(
  seriesFilterFn = () => true,
  seasonFilterFn = () => true,
  options = {}
) {
  const { allSeries, allLabels } = useAppData();

  const { mini, sort = () => 0 } = options;

  const videos = useMemo(
    () =>
      allSeries
        .filter(filterPredicates(seriesFilterFn))
        .flatMap((s) =>
          s.seasons
            .filter(filterPredicates(seasonFilterFn))
            .map((season) => seasonToPosterVideo(s, season, mini, allLabels))
        )
        .sort(sort),
    [allSeries, allLabels, mini, sort, seriesFilterFn, seasonFilterFn]
  );

  return videos;
}
