export const PAGE_IMAGE_ID = 'page-image';
export const COOKIE_CONSENT_KEY = 'cookieConsent';
export const CONTENT_AREA_MAXWIDTH = 1360;

export const CARD_WIDTH = 256;
export const HOME_URL_ADDRESS = 'https://hopetv.fi';

export const BORDER_RADIUS = 8;
export const BORDER_WIDTH = 2;
export const HORIZONTAL_GAP = 12;
export const SCROLL_BUTTON_WIDTH = 40;

export const DEFAULT_SORT_BY = 'episode';
export const DEFAULT_SORT_DIRECTION = 'asc';

export const VIDEO_TYPE_VIMEO = 'vimeo';
export const VIDEO_TYPE_YOUTUBE = 'youtube';
export const VIDEO_TYPE_SELFHOSTED = 'selfhosted';
export const VIDEO_TYPE_NONE = 'none';

export const STORAGE_KEY = 'viewingHistory';
export const HISTORY_SIZE = 30;
