import { useAppData } from 'hooks/useAppData';
import { useMemo } from 'react';
import { moviesUrl } from 'root/urls';
import { filterPredicates } from 'utils/array';

export function movieToPosterVideo(movie, mini) {
  const {
    id,
    slug,
    heroImageUrlPoster,
    heroImageUrlPosterMini,
    title,
    duration,
    produced,
    producers,
    suitableFor,
    videoFormat,
    keywords,
    releasedAt
  } = movie;

  return {
    id,
    url: `${moviesUrl}/${slug}`,
    imageUrl: mini ? heroImageUrlPosterMini : heroImageUrlPoster,
    title,
    info: duration,
    produced,
    producer: producers?.[0],
    suitableFor,
    videoFormat,
    keywords,
    releasedAt,
    updatedAt: releasedAt
  };
}

export function useMovies(
  filterFn = () => true,
  options = {}
) {
  const { allMovies } = useAppData();

  const { mini, sort = () => 0 } = options;

  const videos = useMemo(
    () =>
      allMovies.movies
        .filter(filterPredicates(filterFn))
        .map((movie) => movieToPosterVideo(movie, mini))
        .sort(sort),
    [allMovies.movies, mini, sort, filterFn]
  );

  return videos;
}
