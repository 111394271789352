import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from 'assets/icons/x-circle.svg';
import { cssThemeColor } from 'utils/theme';
import { BrowserCompatibility } from 'components/BrowserCompatibility/BrowserCompatibility';

const ErrorContainer = styled.article`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const StyledBrowserCompatibility = styled(BrowserCompatibility)`
  flex: 0 0 auto;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 0 auto;
`;

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  color: ${cssThemeColor('lightGrey80')};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.p`
  margin-top: 24px;
  max-width: 320px;
  text-align: center;
`;

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorContainer>
          <StyledBrowserCompatibility />
          <Error>
            <IconContainer>
              <ErrorIcon />
            </IconContainer>
            <Description>
              Pahoittelut sivulla tapahtuneesta virheestä. Pyrimme korjaamaan
              ongelman mahdollisimman pian.
            </Description>
          </Error>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}
