export function getRandomItem(list) {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
}

export function filterPredicates(predicates) {
  return Array.isArray(predicates)
    ? (value) => predicates.every((fn) => fn(value))
    : predicates;
}
