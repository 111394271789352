import { ContentContainer } from 'components/ContentContainer/ContentContainer';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/hope-logo.svg';
import {
  cssThemeColor,
  cssThemeSemanticColor,
  cssThemeTypography,
} from 'utils/theme';
import { Link } from 'react-router-dom';
import { media } from 'utils/media';
import { aboutUrl, privacyUrl } from 'root/urls';
import { TextButton } from 'components/Button/TextButton';
import { useCookieConsent } from 'components/CookieConsent/hooks';
import { pxToRem } from 'utils/theme';

const FooterContainer = styled.footer`
  background: ${cssThemeColor('mediumGrey')};
  padding: ${pxToRem(56)} 0;
  position: relative;
  font-size: 0.875rem;
  font-family: ${cssThemeTypography('body')};
  line-spacing: 0;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(56)};

  @media ${media.md} {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  @media ${media.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Column = styled.section`
  @media ${media.md} {
    &:first-child {
      grid-row: span 2;
    }
  }
  @media ${media.lg} {
    &:first-child {
      grid-row: auto;
    }
  }
`;

const LogoContainer = styled.div`
  margin: 0 auto;
  width: ${pxToRem(96)};

  @media ${media.md} {
    margin: 0;
  }
`;

const LinksNav = styled.nav`
  margin-top: ${pxToRem(16)};
`

const LinksList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const LinksListItem = styled.li`
  padding: ${pxToRem(8)} 0;
`;

const Title = styled.h3`
  font-family: ${cssThemeTypography('heading')};
  font-weight: 700;
  font-size: ${pxToRem(18)};
  margin: 0 0 ${pxToRem(8)};
`;

const Text = styled.p`
  margin: 0;
  font-style: normal;

  & + & {
    margin-top: ${pxToRem(16)};
  }
`;

const CopyrightContainer = styled.div`
  border-top: 1px solid ${cssThemeSemanticColor('border')};
  text-align: center;
  font-size: ${pxToRem(12)};
  color: ${cssThemeColor('white80')};
  padding-top: ${pxToRem(32)};
  margin-top: ${pxToRem(56)};
`;

const Copyright = styled.p`
  max-width: ${pxToRem(576)};
  margin: 0 auto;

  & + & {
    margin-top: ${pxToRem(16)};
  }
`;

const currentYear = new Date().getFullYear();

export const Footer = () => {
  const { setModalOpen } = useCookieConsent();

  return (
    <FooterContainer>
      <ContentContainer>
        <Columns>
          <Column>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <LinksNav>
              <LinksList>
                <LinksListItem>
                  <Link to={aboutUrl}>Tietoa palvelusta</Link>
                </LinksListItem>
                <LinksListItem>
                  <Link to={privacyUrl}>Tietosuoja</Link>
                </LinksListItem>
                <LinksListItem>
                  <TextButton onClick={() => setModalOpen(true)}>
                    Muokkaa evästevalintoja
                  </TextButton>
                </LinksListItem>
              </LinksList>
            </LinksNav>
          </Column>
          <Column>
            <header>
              <Title>HopeTV</Title>
            </header>
            <Text>
              HopeTV eli Hope Channel on Suomen Adventtikirkon omistama ja ylläpitämä mediapalvelu.
            </Text>
            <Text>
               Olemme osa maailmanlaajuisen adventtikirkon Hope Channel -verkostoa.
            </Text>
            <Text>
              Yhteistyökumppaneitamme ovat mm.{' '}
              <a
                href="https://www.hopetv.org/"
                target="_blank"
                rel="noreferrer"
              >
                Hope Channel International
              </a>
              ,{' '}
              <a href="https://hopemedia.eu/" target="_blank" rel="noreferrer">
                Hope Media Europe
              </a>
              {' '}ja{' '}
              <a
                href="https://adventistreview.tv/"
                target="_blank"
                rel="noreferrer"
              >
                Adventist Review Media
              </a>
              {' '}sekä{' '}
              <a
                href="https://adventcom.eu/"
                target="_blank"
                rel="noreferrer"
              >
                Global Adventist Internet Network
              </a>
              .
            </Text>
          </Column>
          <Column>
            <header>
              <Title>Yhteystiedot</Title>
            </header>
            <Text as="address">
              Suomen Adventtikirkko / Hope Channel Finland
              <br />
              PL 94
              <br />
              33101 TAMPERE
            </Text>
            <Text>Puhelin (vaihde) 03 3611 111.</Text>
          </Column>
        </Columns>
        <CopyrightContainer>
          <Copyright>
            Tekijänoikeudet &copy; 2019-{currentYear} Suomen Adventtikirkko / Hope
            Channel Finland. Kaikki oikeudet pidätetään. Ohjelmien ja
            tekstityksien tai niiden osien julkaiseminen tai kopioiminen ilman
            Suomen Adventtikirkon myöntämää lupaa on ehdottomasti kielletty. Luvaton käyttö on tekijänoikeusrikos.
          </Copyright>
          <Copyright>
            Tekijänoikeuksien alaisen musiikin mediapienlupa (L-kategoria) ja
            piensynkronointi: Teoston sopimusnumero 70017986.
          </Copyright>
        </CopyrightContainer>
      </ContentContainer>
    </FooterContainer>
  )
};

export default Footer;