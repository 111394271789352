import styled from 'styled-components';
import { media } from 'utils/media';

export const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 24px;
  max-width: 1360px;

  @media ${media.sm} {
    padding: 0 32px;
  }

  @media ${media.md} {
    padding: 0 40px;
  }
`;
