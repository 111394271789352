import {
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import PropTypes from 'prop-types';

export const AnimatedCard = ({
  content,
  children,
  expandOrigin = 'center',
}) => {
  const [refDimensions, setRefDimensions] = useState(null);
  const { x, y, reference, floating, strategy, context, refs } = useFloating({
    open: Boolean(refDimensions),
    placement: 'bottom',
    onOpenChange: (nextOpen) => {
      if (nextOpen) {
        const { width, height } =
          refs.reference.current?.getBoundingClientRect();
        setRefDimensions({ width, height });
      }
    },
    middleware: [offset(({ rects }) => -rects.reference.height)],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { restMs: 80 }),
    useFocus(context),
    useDismiss(context),
  ]);

  return (
    <>
      <div ref={reference} {...getReferenceProps()}>
        {children}
      </div>
      <AnimatePresence>
        {Boolean(refDimensions) && (
          <motion.div
            ref={floating}
            initial={{ scale: 1, translateY: 0 }}
            animate={{ scale: 1.25, translateY: '-20%' }}
            exit={{ scale: 1, translateY: 0 }}
            transition={{
              ease: 'easeInOut',
              duration: 0.2,
            }}
            style={{
              width: refDimensions.width,
              originX: expandOrigin,
              originY: 'center',
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 20,
            }}
            {...getFloatingProps({
              onMouseLeave: () => setRefDimensions(null),
            })}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

AnimatedCard.propTypes = {
  expandOrigin: PropTypes.oneOf(['left', 'right', 'center']),
};
