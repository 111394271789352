import React from 'react';
import { useMatch, useResolvedPath, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { media } from 'utils/media';
import { omitProps } from 'utils/styledComponents';
import { cssThemeColor, cssThemeTypography } from 'utils/theme';

export const navigationLinkBaseStyles = css`
  color: ${({ $isActive }) =>
    $isActive ? cssThemeColor('hopeYellow') : cssThemeColor('white')};
  text-decoration: none;
  position: relative;
  display: block;
  font-family: ${cssThemeTypography('heading_uppercase')};
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 400;

  @media ${media.lg} {
    font-size: 18px;
  }

  @media ${media.xl} {
    font-size: 20px;
  }
`;

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: omitProps(['$isActive']),
})`
  ${navigationLinkBaseStyles}

  &:after {
    content: '';
    display: block;
    height: 2px;
    background: ${({ $isActive }) =>
    $isActive ? cssThemeColor('hopeYellow') : cssThemeColor('white')};
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 100ms;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline-color: transparent;
  }

  &:hover:after,
  &:active::after,
  &:focus::after {
    transform: scale(1, 1);
  }
`;

export const NavigationLink = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved?.pathname, end: false });

  return (
    <StyledLink to={to} $isActive={match} {...props}>
      {children}
    </StyledLink>
  );
};
