import { COOKIE_CONSENT_KEY } from 'constants/constants';
import { VIDEO_TYPE_VIMEO, VIDEO_TYPE_YOUTUBE } from 'constants/constants';
import { isNil } from './function';

export const setCookie = (key, value, expiry, path = '/') => {
  const cookieValue = new URLSearchParams(value);
  let cookieString = `${key}=${cookieValue}; expires=${expiry.toUTCString()}; path=${path}`;

  document.cookie = cookieString;
};

export const getCookie = (key) => {
  const cookieValue = document.cookie
    .split('; ')
    ?.find((item) => item.startsWith(`${key}=`))
    ?.slice(key.length + 1);
  return isNil(cookieValue) ? null : new URLSearchParams(cookieValue);
};

export const getCookieValue = (key, name) => {
  const cookie = getCookie(key);
  return cookie?.get(name);
};

export const deleteCookie = (key) => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const getConsentCookieValues = () => {
  const consentCookie = getCookie(COOKIE_CONSENT_KEY);

  if (isNil(consentCookie?.toString())) {
    return null;
  }

  return {
    technical: consentCookie.get('technical') === 'true',
    [VIDEO_TYPE_VIMEO]: consentCookie.get(VIDEO_TYPE_VIMEO) === 'true',
    [VIDEO_TYPE_YOUTUBE]: consentCookie.get(VIDEO_TYPE_YOUTUBE) === 'true',
  };
};
