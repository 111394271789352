import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cssThemeColor, cssThemeTypography, pxToRem } from 'utils/theme';

const StyledTextButton = styled.button`
  border: none;
  padding: 0;
  background: none;
  color: ${cssThemeColor('hopeYellow')};
  cursor: pointer;
  font-family: ${cssThemeTypography('body')};
  font-size: ${({ $size }) => pxToRem($size === 'small' ? 14 : 16)};
  line-height: ${pxToRem(20)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:hover {
    color: ${cssThemeColor('hopeYellow80')};
  }

  &:active,
  &:focus {
    outline-color: transparent;
    color: ${cssThemeColor('hopeYellow80')};
  }

  &:disabled {
    color: ${cssThemeColor('lightGrey')};
    cursor: not-allowed;
  }

  svg {
    color: ${({ $iconColor }) => $iconColor};
    flex-shrink: 0;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
  }
`;

export const TextButton = ({
  size = 'small',
  type = 'button',
  icon,
  iconColor = 'currentColor',
  iconPlacement = 'left',
  children,
  ...rest
}) => {
  return (
    <StyledTextButton type={type} $size={size} $iconColor={iconColor} {...rest}>
      {icon && iconPlacement === 'left' && icon}
      {children}
      {icon && iconPlacement === 'right' && icon}
    </StyledTextButton>
  );
};

TextButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  icon: PropTypes.node,
  iconColor: PropTypes.string,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
};
