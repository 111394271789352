import { useCallback, useEffect, useState } from 'react';
import { moviesUrl, seriesUrl } from 'root/urls';
import { getStorageItem, setStorageItem } from 'utils/storage';
import { movieToPosterVideo } from 'views/Movies/hooks/useMovies';
import { seasonToPosterVideo } from 'hooks/useSeries';
import { useAppData } from './useAppData';

const STORAGE_KEY = 'favourites';

function getPath(url, pathPrefix, urlPrefix) {
  return [pathPrefix, ...url.split(urlPrefix)[1].slice(1).split('/')];
}

export function getPathFromUrl(url) {
  if (url.startsWith(seriesUrl)) {
    return getPath(url, 'series', seriesUrl);
  }
  if (url.startsWith(moviesUrl)) {
    return getPath(url, 'movie', moviesUrl);
  }
  return [];
}

export function useFavourites() {
  const { getSeasonByPath, getSeriesByPath, getMovie, allLabels } = useAppData();

  const [favourites, setFavourites] = useState(getStorageItem(STORAGE_KEY, []));

  const updateState = useCallback((event) => {
    setFavourites(event.newValue);
  }, []);

  useEffect(() => {
    window.addEventListener('storage', updateState);
    return () => {
      window.removeEventListener('storage', updateState);
    };
  }, [updateState]);

  const updateFavourites = (newValue) => {
    setStorageItem(STORAGE_KEY, newValue);
    const event = new Event('storage');
    event.newValue = newValue;
    window.dispatchEvent(event);
  };

  const addToFavourites = useCallback(({ id, url }) => {
    const path = getPathFromUrl(url);
    if (path.length > 0) {
      const type = path[0];
      const currentValue = getStorageItem(STORAGE_KEY, []);
      updateFavourites([
        { id, type, path },
        ...currentValue.filter((favourite) => favourite.id !== id),
      ]);
    }
  }, []);

  const removeFromFavourites = useCallback((id) => {
    const currentValue = getStorageItem(STORAGE_KEY, []);
    updateFavourites(currentValue.filter((favourite) => favourite.id !== id));
  }, []);

  const clearFavourites = useCallback(() => {
    updateFavourites([]);
  }, []);

  const isFavourite = useCallback(
    (id) => Boolean(favourites.find((f) => f.id === id)),
    [favourites]
  );

  const getAugmentedFavourites = useCallback(
    () =>
      favourites.reduce(
        (acc, favourite) => {
          const { path, type } = favourite;

          if (type === 'series') {
            const series = getSeriesByPath(path);
            const season = getSeasonByPath(path);
            if (season) {
              return {
                ...acc,
                series: [
                  ...acc.series,
                  seasonToPosterVideo(series, season, true, allLabels),
                ],
              };
            }
          }
          if (type === 'movie') {
            const movie = getMovie(path[1]);
            if (movie) {
              return {
                ...acc,
                movies: [...acc.movies, movieToPosterVideo(movie, true)],
              };
            }
          }
          return acc;
        },
        { movies: [], series: [] }
      ),
    [favourites, getSeriesByPath, getSeasonByPath, getMovie, allLabels]
  );

  return {
    favourites,
    isFavourite,
    addToFavourites,
    removeFromFavourites,
    clearFavourites,
    getAugmentedFavourites,
  };
}
