import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { IconButton } from 'components/IconButton/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeSemanticColor, cssThemeTypography } from 'utils/theme';

const Container = styled.header`
  padding: 16px;
  border-bottom: 1px solid ${cssThemeSemanticColor('border')};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  @media ${media.md} {
    padding: 24px;
  }
`;

const Header = styled.h3`
  font-family: ${cssThemeTypography('heading')};
  font-size: 18px;
  margin: 0;
  flex-shrink: 0;
  align-self: flex-start;
  flex: 1;

  @media ${media.md} {
    font-size: 24px;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

const HeaderContent = styled.div`
  flex-shrink: 0;
`;

const CloseButton = styled(IconButton)`
  flex: 0 0 auto;
`;

export const ModalHeader = ({ children, headerContent, onDismiss }) => {
  return (
    <Container>
      <Header>{children}</Header>
      {headerContent && <HeaderContent>{headerContent}</HeaderContent>}
      <CloseButton icon={<CloseIcon />} onClick={onDismiss} />
    </Container>
  );
};

ModalHeader.propTypes = {
  onDismiss: PropTypes.func,
};
