import React from 'react';
import styled from 'styled-components';
import logo from 'assets/hope-logo.svg';
import { ResponsiveImage } from 'components/ResponsiveImage/ResponsiveImage';
import { cssThemeColor } from 'utils/theme';

const Container = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
`;

const Logo = styled(ResponsiveImage)`
  width: 120px;
`;

const Circles = styled.div`
  display: flex;
  gap: 12px;
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: bounce 0.8s ease-in-out infinite;
  background: ${cssThemeColor('hopeYellow')};

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  &:nth-child(4) {
    animation-delay: 0.6s;
  }

  @keyframes bounce {
    0% {
      background: ${cssThemeColor('hopeYellow30')};
      transform: translateY(0px);
    }
    50% {
      background: ${cssThemeColor('hopeYellow')};
      transform: translateY(-32px);
    }
    100% {
      background: ${cssThemeColor('hopeYellow30')};
      transform: translateY(0px);
    }
  }
`;

export function PageLoader() {
  return (
    <Container>
      <Logo defaultSrc={logo} title="Logo" width="120px" height="auto" svgFile/>
      <Circles>
        <Circle className="circle"></Circle>
        <Circle className="circle"></Circle>
        <Circle className="circle"></Circle>
        <Circle className="circle"></Circle>
      </Circles>
    </Container>
  );
}
