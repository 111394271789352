import { Link } from 'react-router-dom';
import { Topic } from 'components/Topic/Topic';
import styled from 'styled-components';
import { cssThemeColor, pxToRem } from 'utils/theme';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';

export const StyledLink = styled(Link)`
  white-space: nowrap;
  transition: all 400ms ease-in-out;
  opacity: 0;
  transform: translateX(-20%);

  &:hover {
    text-decoration: none;
  }
`;

export const ShowAllIcon = styled.div`
  color: ${cssThemeColor('hopeYellow')};
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  flex: 0 0 ${pxToRem(24)};
  transition: all 200ms ease-in-out;
  opacity: 0.35;
  position: absolute;
  right: calc(100% - ${pxToRem(24)});
`;

export const LinkContainer = styled.div`
  display: flex;
  overflow: hidden;
  padding-left: ${pxToRem(12)};
  padding-right: ${pxToRem(24)};
  position: relative;

  &:hover {
    ${StyledLink} {
      opacity: 100;
      transform: translateX(0);
    }
    ${ShowAllIcon} {
      opacity: 100;
      right: 0px;
    }
  }
`;

export const TopicContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1rem;

  &:hover {
    ${ShowAllIcon} {
      opacity: 100;
    }
  }
`;

export const StyledTopic = styled(Topic)`
  margin: 0;

  &:hover {
    & + ${LinkContainer} {
      ${StyledLink} {
        opacity: 100;
        transform: translateX(0);
      }
      ${ShowAllIcon} {
        opacity: 100;
        right: 0;
      }
    }
  }
`;

export const ExpandingLink = ({ title, linkTo, linkText, inactive = false }) => {
  return (
    <TopicContainer>
      <StyledTopic title={title} />
      {!inactive && (
        <LinkContainer>
          <StyledLink to={linkTo}>{linkText}</StyledLink>
          <ShowAllIcon>
            <ChevronRightIcon />
          </ShowAllIcon>
        </LinkContainer>
      )}
    </TopicContainer>
  )
}