import VimeoPlayer from '@vimeo/player';

export const vimeoPlayer = ({ embedRef, videoTag, seconds = -1 }) => {
  const player = new VimeoPlayer(embedRef, {
    autoplay: seconds < 0,
    url: `https://player.vimeo.com/video/${videoTag}`,
    texttrack: 'fi',
    quality: 'auto'
  });

  async function getCurrentTime() {
    return await player.getCurrentTime();
  }

  async function getDuration() {
    return await player.getDuration();
  }

  async function cleanUp() {
    player.off('ended');
    await player.destroy();
  }

  async function play() {
    try {
      await player.enableTextTrack('fi', 'subtitles');
    } catch {
      player.disableTextTrack();
    } finally {
      await player.play();
    }
  }

  async function onEnd(callback) {
    player.on('ended', callback);
  }

  async function resume() {
    try {
      await player.setCurrentTime(seconds >= 0 ? seconds : 0);
    } catch {
      player.setCurrentTime(0);
    }
    finally {
      play();
    }
  }

  return {
    play,
    resume,
    getCurrentTime,
    getDuration,
    onEnd,
    cleanUp
  };
};
