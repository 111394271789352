import styled from 'styled-components';
import { cssThemeColor, cssThemeSemanticColor } from 'utils/theme';

export const ThinScrollbarContainer = styled.div`
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0) transparent;
  /* Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 3px solid ${cssThemeSemanticColor('border')};
  }
  &:hover {
    scrollbar-color: ${cssThemeColor('lightGrey')} transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${cssThemeColor('lightGrey')};
  }
`;
