import { VIDEO_TYPE_VIMEO, VIDEO_TYPE_YOUTUBE } from 'constants/constants';
import React from 'react';
import { CookieBanner } from './CookieBanner';
import { CookieConsentModal } from './CookieConsentModal';
import { useCookieConsent } from './hooks';

export const CookieConsent = () => {
  const { isConsentSet, setConsentOptions, modalOpen, setModalOpen } =
    useCookieConsent();

  return (
    <>
      <CookieBanner
        isVisible={!modalOpen && !isConsentSet}
        onAccept={() =>
          setConsentOptions({
            technical: true,
            [VIDEO_TYPE_VIMEO]: true,
            [VIDEO_TYPE_YOUTUBE]: true,
          })
        }
        onShowOptions={() => setModalOpen(true)}
      />
      <CookieConsentModal
        isVisible={modalOpen}
        onDismiss={() => setModalOpen(false)}
      />
    </>
  );
};
