import { Button } from 'components/Button/Button';
import { Hero } from 'components/Hero/Hero';
import { PageImage } from 'components/PageImage/PageImage';
import { PageWrapperBlock } from 'components/Wrapper/PageWrapper';
import { Link } from 'react-router-dom';
import { moviesUrl, seriesUrl } from 'root/urls';
import { getRandomItem } from 'utils/array';

export const HomeHeroBlock = ({ allSeries, allMovies }) => {
  const randomType = Math.random();

  let randomSeason = getRandomItem(allMovies.movies);
  let title = randomSeason.title;
  let subtitle = null;
  let gotoUrl = moviesUrl;
  let details = randomSeason.overview;
  let isHopeProduction = randomSeason.isHopeProduction;
  let heroImageUrlTitleLogo = randomSeason.heroImageUrlTitleLogo;

  if (randomType > 0.49) {
    const randomSeries = getRandomItem(allSeries);
    randomSeason = getRandomItem(randomSeries.seasons);
    title = randomSeries.title;
    subtitle = randomSeason.title;
    gotoUrl = `${seriesUrl}/${randomSeries.slug}`;
    details = randomSeason.details;
    if (details === null) details = randomSeries.overview;
    isHopeProduction = randomSeries.isHopeProduction;
    heroImageUrlTitleLogo = randomSeries.heroImageUrlTitleLogo;
  }

  gotoUrl = `${gotoUrl}/${randomSeason.slug}`;

  return (
    <PageWrapperBlock>
      <PageImage
        landscape={randomSeason.heroImageUrlLandscape}
        portrait={randomSeason.heroImageUrlPortrait}
      />

      <Hero
        title={title}
        subtitle={subtitle}
        details={details}
        isHopeProduction={isHopeProduction}
        heroImageUrlTitleLogo={heroImageUrlTitleLogo}
        kicker="Suosittelemme"
      >
        <Button as={Link} to={gotoUrl}>
          Katso
        </Button>
      </Hero>
    </PageWrapperBlock>
  );
};
