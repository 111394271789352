import { COOKIE_CONSENT_KEY } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { getConsentCookieValues, setCookie } from 'utils/cookie';
import { isNil, isValue } from 'utils/function';
import { CookieConsentContext } from './context';

const getSelectedOptions = () => {
  const consentCookieValues = getConsentCookieValues();

  if (isNil(consentCookieValues)) {
    return null;
  }

  const { vimeo, youtube } = consentCookieValues;

  let selectedOptions = { technical: false };
  if (vimeo) {
    selectedOptions.vimeo = true;
    selectedOptions.technical = true;
  }
  if (youtube) {
    selectedOptions.youtube = true;
    selectedOptions.technical = true;
  }

  return selectedOptions;
};

export const CookieConsentProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [consentOptions, setConsentOptions] = useState(getSelectedOptions());

  useEffect(() => {
    if (isValue(consentOptions) && Object.keys(consentOptions).length > 0) {
      const expiry = new Date();
      expiry.setFullYear(expiry.getFullYear() + 1);
      setCookie(COOKIE_CONSENT_KEY, consentOptions, expiry);
    }
    if (!consentOptions) {
      setModalOpen(true);
    }
  }, [consentOptions]);

  const value = { consentOptions, setConsentOptions, modalOpen, setModalOpen };

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  );
};
