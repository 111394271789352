export const aboutUrl = '/tietoa-palvelusta';
export const donateUrl = '/lahjoita';
export const favouritesUrl = '/suosikit';
export const historyUrl = '/historia';
export const kidsUrl = '/lapsille';
export const literatureUrl = '/kirjallisuus';
export const moviesUrl = '/elokuvat';
export const podcastUrl = '/podcast';
export const privacyUrl = '/tietosuoja';
export const searchUrl = '/haku';
export const seriesUrl = '/sarjat';
export const settingsUrl = '/asetukset';