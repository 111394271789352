import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { cssThemeSemanticColor } from 'utils/theme';

const Header = styled.header`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${cssThemeSemanticColor('border')};
  padding: 16px 8px;
`;

const HeaderIcon = styled.div`
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  transition: transform 0.2s;
  transform: rotate(${({ $open }) => ($open ? '90deg' : '0')});
`;

export const AccordionItem = ({
  item,
  open,
  setOpen,
  renderHeader,
  renderContent,
}) => {
  const { id } = item;
  return (
    <article>
      <Header onClick={() => setOpen(id)}>
        <HeaderIcon $open={open}>
          <ChevronRightIcon />
        </HeaderIcon>
        {renderHeader(item)}
      </Header>
      <AnimatePresence initial={false}>
        {open && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2 }}
          >
            {renderContent(item)}
          </motion.section>
        )}
      </AnimatePresence>
    </article>
  );
};

AccordionItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  renderHeader: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
};
