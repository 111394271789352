import logoIconOnly from 'assets/hope-logo-icon.svg';
import logo from 'assets/hope-logo.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
// import { ReactComponent as SettingsIcon } from 'assets/icons/gear-svgrepo-com.svg';
import { IconButton } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';
import { ResponsiveImage } from 'components/ResponsiveImage/ResponsiveImage';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  donateUrl,
  favouritesUrl,
  kidsUrl,
  literatureUrl,
  moviesUrl,
  searchUrl,
  // podcastUrl,
  seriesUrl,
  // settingsUrl
} from 'root/urls';
import styled, { css } from 'styled-components';
import { debounce } from 'utils/function';
import { breakpoints, media } from 'utils/media';
import { cssThemeColor } from 'utils/theme';
import { pxToVw } from 'utils/window';
import { MobileNavigationList } from './MobileNavigationList';
import { MobileSearch } from './MobileSearch';
import { NavigationLink } from './NavigationLink';

const MIN_HEIGHT_XS = 70;
const MIN_HEIGHT_LG = 90;

const NavigationContainer = styled.section`
  position: sticky;
  top: 0;
  z-index: 30;

  ${({ $isMobileMenuShown, $scrollPosition }) => {
    if ($isMobileMenuShown || $scrollPosition > MIN_HEIGHT_XS) {
      return css`
        background: ${cssThemeColor('darkGrey95')};
      `;
    }
    return css`
      background: linear-gradient(
        ${cssThemeColor('darkGrey')},
        ${cssThemeColor('darkGrey15')}
      );
    `;
  }}

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  min-height: 70px;
  gap: 24px;
  transition: background 0.2s ease-in-out;

  @media ${media.lg} {
    min-height: 90px;
    padding: 16px 24px;
    gap: max(16px, ${pxToVw(64, breakpoints.xl)}vw);

    ${({ $scrollPosition }) => {
    if ($scrollPosition > MIN_HEIGHT_LG) {
      return css`
          background: ${cssThemeColor('darkGrey95')};
        `;
    }
    return null;
  }}
  }

  @media ${media.xl} {
    gap: 64px;
  }
`;

const LogoLink = styled(Link)`
  flex: 0 0 44px;
  outline-color: transparent;

  @media ${media.lg} {
    flex: 0 0 80px;
  }
`;

const Logo = styled(ResponsiveImage)`
  width: 100%;
`;

const Nav = styled.nav`
  flex: 1 0 auto;
`;

const RightNav = styled(Nav)`
  display: flex;
  justify-content: flex-end;
`;

const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${media.lg} {
    gap: max(16px, ${pxToVw(32, breakpoints.xl)}vw);
  }

  @media ${media.xl} {
    gap: 32px;
  }
`;

export const Navigation = () => {
  const navigationRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const isDesktop = useMatchMedia(media.lg);
  const isMobileSearchShown = !isDesktop && showMobileSearch;
  const isMobileMenuShown = !isDesktop && showMobileMenu;

  useEffect(() => {
    const scroll = debounce(() => {
      setScrollPosition(window.scrollY);
    }, 10);

    if (navigationRef.current) {
      window.addEventListener('scroll', scroll);
    }

    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  const handleSearchChange = useCallback(
    (e) => {
      const value = e.target.value;

      if (value.length === 0) {
        return navigate('/');
      }
      if (location.pathname !== searchUrl) {
        return navigate(`${searchUrl}?query=${value}`, {
          state: location.pathname,
        });
      }
      setSearchParams({ query: value });
    },
    [location, navigate, setSearchParams]
  );

  return (
    <>
      <NavigationContainer
        ref={navigationRef}
        $isMobileMenuShown={isMobileMenuShown || isMobileSearchShown}
        $scrollPosition={scrollPosition}
      >
        <LogoLink to="/" onClick={() => setShowMobileMenu(false)}>
          <Logo
            sources={[
              { media: media.max.md, src: logoIconOnly },
              { media: media.lg, src: logo },
            ]}
            defaultSrc={logoIconOnly}
            title="Logo"
            svgFile
          />
        </LogoLink>
        {isDesktop && (
          <>
            <Nav>
              <NavigationList>
                <li>
                  <NavigationLink to={moviesUrl}>Elokuvat</NavigationLink>
                </li>
                <li>
                  <NavigationLink to={seriesUrl}>Sarjat</NavigationLink>
                </li>
                <li>
                  <NavigationLink to={kidsUrl}>Lapsille</NavigationLink>
                </li>
                {/* Pois käytöstä toistaiseksi
                <li>
                  <NavigationLink to={podcastUrl}>Podcast</NavigationLink>
                </li>
                */}
                <li>
                  <NavigationLink to={literatureUrl}>
                    Kirjallisuus
                  </NavigationLink>
                </li>
              </NavigationList>
            </Nav>
            <RightNav>
              <NavigationList>
                <li>
                  <Input
                    placeholder="Hae sivustolta..."
                    icon={<SearchIcon />}
                    iconPlacement="right"
                    type="text"
                    value={searchParams.get('query') ?? ''}
                    onChange={handleSearchChange}
                  />
                </li>
                <li>
                  <NavigationLink to={donateUrl}>Lahjoita</NavigationLink>
                </li>
                <li>
                  <NavigationLink to={favouritesUrl}>Suosikit</NavigationLink>
                </li>
                {/* <li>
                  <NavigationLink to={settingsUrl}>
                    <IconButton
                      title="Asetukset"
                      icon={<SettingsIcon />}
                      onClick={null}
                    />
                  </NavigationLink>
                </li> */}
              </NavigationList>
            </RightNav>
          </>
        )}
        {!isDesktop && (
          <>
            <RightNav>
              <NavigationList>
                <li>
                  <IconButton
                    title="Haku"
                    variant={isMobileSearchShown ? 'hopeYellow' : 'white'}
                    icon={<SearchIcon />}
                    onClick={() => {
                      setShowMobileMenu(false);
                      setShowMobileSearch((open) => !open);
                    }}
                  />
                </li>
                <li>
                  <IconButton
                    title="Valikko"
                    icon={showMobileMenu ? <CloseIcon /> : <MenuIcon />}
                    onClick={() => {
                      setShowMobileSearch(false);
                      setShowMobileMenu((open) => !open);
                    }}
                  />
                </li>
                {/* <li>
                  <NavigationLink to={settingsUrl}>
                    <IconButton
                      title="Asetukset"
                      icon={<SettingsIcon />}
                      onClick={null}
                    />
                  </NavigationLink>
                </li> */}
              </NavigationList>
            </RightNav>
          </>
        )}
      </NavigationContainer>

      <MobileSearch
        isVisible={isMobileSearchShown}
        value={searchParams.get('query') ?? ''}
        onChange={handleSearchChange}
      />

      <MobileNavigationList
        isVisible={isMobileMenuShown}
        onNavigate={() => setShowMobileMenu(false)}
      />
    </>
  );
};
