
import { PosterCard } from 'components/PosterCard/PosterCard';
import { PageWrapper } from 'components/Wrapper/PageWrapper';
import { CARD_WIDTH } from 'constants/constants';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'utils/function';
import { cssThemeColor, pxToRem } from 'utils/theme';
import { getStickerText } from 'utils/utils';

const HORIZONTAL_GAP = 12;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ $multiplier }) =>
    `repeat(auto-fill, minmax(${CARD_WIDTH * $multiplier}px, 1fr))`};
  gap: 1rem ${pxToRem(HORIZONTAL_GAP)};
`;



const Filters = styled.div`
  border-top: 2px solid ${cssThemeColor('white20')};
  padding: ${pxToRem(16)} 0 ${pxToRem(32)};
`;

export default function PosterGrid({
  wrap = true,
  videos,
  mini,
  cardSizeMultiplier = 1,
  filters,
  aspectRatio,
  isLazy
}) {
  const gridRef = useRef(null);
  const [columnCount, setColumnCount] = useState();

  useEffect(() => {
    const resize = debounce(() => {
      if (gridRef.current) {
        setColumnCount(
          Math.floor(
            (gridRef.current.offsetWidth + HORIZONTAL_GAP) /
            (CARD_WIDTH + HORIZONTAL_GAP)
          )
        );
      }
    }, 10);

    if (gridRef.current) {
      window.addEventListener('resize', resize);
      resize();
    }

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const getExpandOrigin = useCallback(
    (index) => {
      if ((index + 1) % columnCount === 1) {
        return 'left';
      }
      if ((index + 1) % columnCount === 0) {
        return 'right';
      }
      return 'center';
    },
    [columnCount]
  );

  const Wrapper = wrap ? PageWrapper : Fragment;

  const GridItems = videos.map((video, index) => {
    const sticker = getStickerText( video.releasedAt, video.updatedAt, video.id );
    return (<PosterCard
      key={video.id}
      {...video}
      expand={columnCount > 1 && mini}
      expandOrigin={getExpandOrigin(index)}
      aspectRatio={mini ? 56.25 : aspectRatio}
      isLazy={isLazy}
      sticker={sticker}
    />)
  });

  return (
    <>
      <Wrapper>
        {filters && <Filters>{filters}</Filters>}
        <Grid $multiplier={cardSizeMultiplier} ref={gridRef}>
          {GridItems}
        </Grid>
      </Wrapper>
    </>
  );
}

PosterGrid.propTypes = {
  wrap: PropTypes.bool,
  aspectRatio: PropTypes.number,
  topic: PropTypes.string,
  topicUrl: PropTypes.string,
  mini: PropTypes.bool,
  cardSizeMultiplier: PropTypes.number,
  filters: PropTypes.node,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      imageUrl: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      sticker: PropTypes.string,
      duration: PropTypes.string,
      episodes: PropTypes.string,
      produced: PropTypes.string,
      suitableFor: PropTypes.string,
    })
  ),
};
