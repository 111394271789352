import styled, { css } from 'styled-components';
import { breakpoints, media } from 'utils/media';
import { cssThemeColor, cssThemeTypography, cssThemeSemanticColor, pxToRem } from "utils/theme";
import { preferredRem, preferredViewportWidth } from 'utils/typography';
import { Tag } from "components/Tag/Tag";
import { BORDER_RADIUS, BORDER_WIDTH } from 'constants/constants';
import { ExpandingLink } from 'components/ExpandingLink/ExpandingLink';
import { useAppData } from 'hooks/useAppData';
import { PageWrapper } from 'components/Wrapper/PageWrapper';

export const fluidFontSize = (fontSizeMin, fontSizeMax) => {
	const vw = preferredViewportWidth(
		breakpoints.sm,
		breakpoints.xl,
		fontSizeMin,
		fontSizeMax
	);
	const rem = preferredRem(
		breakpoints.sm,
		breakpoints.xl,
		fontSizeMin,
		fontSizeMax
	);

	return css`
	  font-size: ${fontSizeMin}px;
	  font-size: clamp(
		${fontSizeMin / 16}rem,
		${vw}vw + ${rem}rem,
		${fontSizeMax / 16}rem
	  );
	`;
};

export const OuterLimits = () => {
	return css`
      max-width: 80%;
      @media ${media.md} {
         max-width: 65%;
      }
      @media ${media.lg} {
         max-width: 50%;
      }
   `;
}

export const textShadow = css`
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

export const Details = styled.p`
	${OuterLimits}
	margin: 0.8rem 0 0;
	font-size: 0.9rem;
	font-family: ${cssThemeTypography("body")};
`;

export const InfoList = styled.div`
   ${OuterLimits}
	display: flex;
	text-transform: uppercase;
	font-size: 0.8rem;
	margin-top: 0.2rem;
	font-family: ${cssThemeTypography("keyword")};
	span {
		margin-right: 0.3rem;
		font-variant: normal;
		text-transform: none;
		color: #bbbbbb;
	}
`;

export const Info = styled.div`
	color: white;
	text-shadow: 1px 1px black;
	margin-top: 6rem;
	margin-bottom: 1rem;
`;

export const Presenters = styled.p`
   ${OuterLimits}
   font-size: 0.8rem;
	margin: 0.8rem 0 0;
	span {
		color: #bbbbbb;
	} 
`;

export const Producers = styled.p`
	font-size: 0.8rem;
	margin: 1rem 0 0;
	font-family: ${cssThemeTypography("heading_hero")};
`;

export const Production = styled.div`
	font-size: 0.8rem;
	margin-top: 1rem;
	font-family: ${cssThemeTypography("heading_hero")};
`;

export const Seasons = styled.p`
	font-size: 0.8rem;
	margin: 0.2rem 0 0;
`;

export const RedSticker = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 15;
	font-family: ${cssThemeTypography("button")};
	font-size: ${pxToRem(14)};
	font-weight: 700;
	line-height: 1;
	width: fit-content;
	text-transform: uppercase;
	color: ${cssThemeColor("white")};
	background-color: ${cssThemeColor("hopeRed")};
	border-radius: 0 ${BORDER_RADIUS}px 0 ${BORDER_RADIUS}px;
	padding: 4px 8px;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
`;

export const YellowSticker = styled(RedSticker)`
	color: ${cssThemeSemanticColor('textSecondary')};
	background-color: ${cssThemeColor('hopeYellow')};
	left: ${({ $videocard }) => ($videocard ? '0' : -BORDER_WIDTH)}px;
	top: ${({ $videocard }) => ($videocard ? '0' : -BORDER_WIDTH)}px;
	right: auto;
	border-radius: ${BORDER_RADIUS}px 0 ${BORDER_RADIUS}px 0;
`;

export const SubTitle = styled.h2`
	${textShadow}

	text-align: ${({ $align }) => $align};
	font-family: ${cssThemeTypography("heading_hero")};
	font-weight: 700;
	${fluidFontSize(24, 36)}
	line-height: 1.2;
	color: ${cssThemeColor("hopeOrange")};
	z-index: 5;
	margin: 0;
`;

export const TagContainer = styled.div`
	display: flex;
	font-size: 0.9rem;
	margin: ${pxToRem(8)} 0 ${pxToRem(32)};
	text-shadow: 1px 1px black;
	gap: ${pxToRem(8)};
`;

export const Title = styled.h1`
	${textShadow}

	margin: 0;
	text-transform: uppercase;
	text-align: ${({ $align }) => $align};
	font-family: ${cssThemeTypography("heading_hero")};
	font-weight: 700;
	${fluidFontSize(36, 56)}
	color: ${cssThemeColor("hopeOrange")};
	line-height: 1.1;
	z-index: 5;

	@media ${media.md} {
	  margin-bottom: 16px;
	}
`;

const Dot = styled.span`
   margin: ${pxToRem(6)};
   height: ${pxToRem(4)};
   width: ${pxToRem(4)};
   background-color: ${cssThemeColor("hopeOrange")};
   border-radius: 50%;
   display: inline-block;
`;

const SubtitleCheck = ({ subtitles, allLabels }) => {
	if (!subtitles[0] || subtitles[0] === "") return (
		<>
			<span>{allLabels.labelListsNoSubtitles}</span>
		</>
	)
	return (
		<>
			<span>{allLabels.labelListsSubtitleLanguages}</span>
			{subtitles}
		</>
	);
};

export const ShowInfoLists = ({ genres, keywords, audio, subtitles, allLabels }) => {
	return (
		<>
			<InfoList>
				<span>{allLabels.labelListsGenres}</span>
				{genres}
				<Dot />
				<span>{allLabels.labelListsKeywords}</span>
				{keywords}
			</InfoList>
			<InfoList>
				<span>{allLabels.labelListsAudio}</span>
				{audio}
				<Dot />
				<SubtitleCheck subtitles={subtitles} allLabels={allLabels} />
			</InfoList>
		</>
	);
};

export const DetailTags = ({ suitableFor, videoFormat }) => {
	return (
		<>
			<Tag radius="none" size="tiny" variant="outlineInfo">
				{suitableFor}
			</Tag>
			<Tag radius="none" size="tiny" variant="outlineInfo">
				{videoFormat}
			</Tag>
		</>
	);
};

const ImageArea = styled.div`
	max-width: 400px;
`;

export const TitleImage = ({ image, alt }) => {
	return (
		<ImageArea>
			<img src={`${image}.webp`} alt={`${alt}`} width="100%" />
		</ImageArea>
	);
};

export const TopicWithExpandingLink = ({topic, topicUrl, inactive }) => {
	const { allLabels } = useAppData();
	return (
		<>
			<PageWrapper>
				<ExpandingLink
					title={topic}
					linkTo={topicUrl}
					linkText={allLabels.labelExpandingLinkShowAll}
					inactive={inactive}
				/>
			</PageWrapper>
		</>
	)
}

const Category = styled.h3`
  margin: 2.5rem 0 1.5rem 0;
  color: ${cssThemeColor('hopeYellow80')};
  font-family: ${cssThemeTypography('heading')};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const CategoryTitle = ({category}) => {
	return (
		<>
			<PageWrapper>
				<Category>
					{category}
				</Category>
			</PageWrapper>
		</>
	);
}