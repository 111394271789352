import React, { useMemo } from 'react';
import { Notification } from '../Notification/Notification';
import Requirements from 'components/Requirements/Requirements';
import styled from 'styled-components';

const Title = styled.p`
  margin-top: 0;
`;

export function BrowserCompatibility({ className }) {
  let compabilityTest;

  try {
    compabilityTest = CSS?.supports('aspect-ratio: 16/9'); // Chrome 88 / Edge 88 / Safari 15 / Firefox 89 / Opera 74 / Safari iOS 15 / Samsung 15
  } catch { }

  const isModern = useMemo(
    () => [compabilityTest].every((result) => Boolean(result)),
    [compabilityTest]
  );

  if (isModern) {
    return null;
  }

  return (
    <Notification variant="warn" className={className}>
      <Title>
        Voi ei! Havaitsimme epäyhteensopivuuden selaimesi ja järjestelmämme välillä. Ei hätää. Me kerromme, mikä meni pieleen.
      </Title>
      <Requirements />
    </Notification>
  );
}
