import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'root/theme';
import styled from 'styled-components';
import { cssThemeColor, cssThemeTypography, pxToRem } from 'utils/theme';

const buttonVariants = {
  primary: {
    bg: theme.colors.hopeYellow,
    hoverBg: theme.colors.hopeYellow80,
    color: theme.semanticColors.textSecondary,
  },
  secondary: {
    bg: theme.colors.lightGrey,
    hoverBg: theme.colors.lightGrey80,
    color: theme.semanticColors.textSecondary,
  },
};

const StyledButton = styled.button`
  border: none;
  padding: ${({ $size }) => {
    if ($size === 'tiny') {
      return `${pxToRem(4)} ${pxToRem(16)}`;
    }
    return `${pxToRem(8)} ${pxToRem(24)}`;
  }};
  background: ${({ $variant }) => buttonVariants[$variant].bg};
  color: ${({ $variant }) => buttonVariants[$variant].color};
  font-family: ${cssThemeTypography('button')};
  font-size: ${({ $size }) => {
    if ($size === 'tiny') {
      return pxToRem(12);
    }
    if ($size === 'small') {
      return pxToRem(16);
    }
    return pxToRem(18);
  }};
  line-height: ${pxToRem(22)};
  text-transform: uppercase;
  border-radius: ${pxToRem(4)};
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(8)};

  &:hover:not(:disabled) {
    background: ${({ $variant }) => buttonVariants[$variant].hoverBg};
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline-color: transparent;
    box-shadow: 0 0 0 2px ${cssThemeColor('hopeYellow50')};
  }

  &:disabled {
    color: ${cssThemeColor('darkGrey30')};
    background: ${cssThemeColor('lightGrey')};
    cursor: not-allowed;
  }

  svg {
    flex-shrink: 0;
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
  }
`;

export const Button = ({
  as,
  icon,
  iconPlacement = 'left',
  type = 'button',
  variant = 'primary',
  size = 'medium',
  children,
  ...rest
}) => {
  return (
    <StyledButton as={as} type={type} $variant={variant} $size={size} {...rest}>
      {icon && iconPlacement === 'left' && icon}
      {children}
      {icon && iconPlacement === 'right' && icon}
    </StyledButton>
  );
};

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  $variant: PropTypes.oneOf(['primary', 'secondary']),
  $size: PropTypes.oneOf(['tiny', 'small', 'medium']),
  icon: PropTypes.node,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
};
