import { ReactComponent as PreviousIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as NextIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play-circle.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import { Button } from 'components/Button/Button';
import { TextButton } from 'components/Button/TextButton';
import { Modal } from 'components/Modal/Modal';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { useCountdown } from 'hooks/useCountdown';
import { usePrevious } from 'hooks/usePrevious';
import { useVideoPlayer } from 'hooks/useVideoPlayer';
import { useViewingHistory } from 'hooks/useViewingHistory';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isNil } from 'utils/function';
import { media } from 'utils/media';
import { cssThemeColor } from 'utils/theme';
import { getVideoPlayer } from './utils';

const StyledModal = styled(Modal)`
  justify-content: space-between;
`;

const Content = styled.div`
  flex: 1;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.md} {
    padding: 24px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background: black;
  position: relative;

  @media ${media.max.md} and (orientation: landscape) {
    width: auto;
    max-width: 100%;
    min-height: 100%;
  }

  /* @media ${media.md} {
    width: 100%;
  } */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    display: block;
  }
`;

const VideoOverlay = styled.div`
  background: ${cssThemeColor('darkGrey50')};
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  @media ${media.md} {
    padding: 24px;
  }
`;

const PlaylistActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const VideoPlayer = () => {
  const embedRef = useRef();

  const {
    video,
    path,
    playlist,
    isVisible,
    close,
    previous,
    next,
    isFirst,
    isLast,
  } = useVideoPlayer();
  const { addViewing, getViewing } = useViewingHistory();
  const { countDown, startCountdown, resetCountdown } = useCountdown(5);

  const [player, setPlayer] = useState();
  const [showResume, setShowResume] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const prevVideo = usePrevious(video);

  const createPlayer = useCallback(
    async (onEnd) => {
      if (player && prevVideo?.videoTag === video?.videoTag) {
        return;
      }
      if (!isNil(player)) {
        await player?.cleanUp();
      }
      const previousViewing = video
        ? getViewing({
          type: video.videoType,
          videoId: video.videoTag,
        })
        : null;

      const videoPlayer = getVideoPlayer({
        embedRef: embedRef.current,
        videoType: video?.videoType,
        videoTag: video?.videoTag,
        seconds: previousViewing?.seconds,
      });

      videoPlayer?.onEnd(onEnd);

      setShowResume(Boolean(previousViewing));
      setPlayer(videoPlayer);
    },
    [video, prevVideo, player, getViewing]
  );

  const destroyPlayer = useCallback(async () => {
    await player?.cleanUp();
  }, [player]);

  const onEnd = useCallback(() => {
    if (!isLast) {
      setShowNext(true);
      startCountdown();
    }
  }, [isLast, startCountdown]);

  const addToViewingHistory = useCallback(async () => {
    const currentTime = await player?.getCurrentTime();
    const duration = await player?.getDuration();
    if (
      video?.videoType &&
      video?.videoTag &&
      path &&
      currentTime != null &&
      !showResume
    ) {
      addViewing({
        videoId: video?.videoTag,
        type: video?.videoType,
        path,
        seconds: currentTime,
        duration,
      });
    }
  }, [player, video?.videoType, video?.videoTag, path, showResume, addViewing]);

  const playNext = useCallback(async () => {
    resetCountdown();
    await addToViewingHistory();
    next();
  }, [addToViewingHistory, next, resetCountdown]);

  useEffect(() => {
    if (embedRef.current) {
      createPlayer(onEnd);
    }
    return () => {
      destroyPlayer();
    };
  }, [video, createPlayer, destroyPlayer, onEnd]);

  useEffect(() => {
    if (countDown === 0) {
      setShowNext(false);
      playNext();
    }
  }, [countDown, playNext]);

  async function onDismiss() {
    await addToViewingHistory();
    close();
  }

  async function play() {
    setShowResume(false);
    await player?.play();
  }

  async function resume() {
    setShowResume(false);
    await player?.resume();
  }

  async function playPrevious() {
    await addToViewingHistory();
    previous();
  }

  return (
    <StyledModal
      variant="fluidExtraWide"
      isVisible={isVisible}
      onDismiss={onDismiss}
    >
      <ModalHeader
        headerContent={
          playlist &&
          playlist.length > 0 && (
            <PlaylistActions>
              <TextButton
                icon={<PreviousIcon />}
                disabled={isFirst}
                onClick={playPrevious}
              >
                Edellinen
              </TextButton>
              <TextButton
                icon={<NextIcon />}
                iconPlacement="right"
                disabled={isLast}
                onClick={playNext}
              >
                Seuraava
              </TextButton>
            </PlaylistActions>
          )
        }
        onDismiss={onDismiss}
      >
        {video?.title}
      </ModalHeader>
      <Content>
        <VideoContainer ref={embedRef} />
        {showResume && (
          <VideoOverlay>
            <Button icon={<PlayIcon />} onClick={resume}>
              Jatka
            </Button>
            <Button variant="secondary" icon={<RefreshIcon />} onClick={play}>
              Aloita alusta
            </Button>
          </VideoOverlay>
        )}
        {showNext && !isLast && (
          <VideoOverlay>
            <Button
              icon={<PlayIcon />}
              onClick={() => {
                setShowNext(false);
                playNext();
              }}
            >
              Toista seuraava jakso
            </Button>
            <p>Seuraava jakso toistetaan {countDown} sekunnin kuluttua.</p>
          </VideoOverlay>
        )}
      </Content>
    </StyledModal>
  );
};
