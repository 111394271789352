import { css } from 'styled-components';

import RobotoCondensedRegularWoff2 from '../assets/fonts/roboto-condensed-v19-latin-regular.woff2';
import RobotoCondensed700Woff2 from '../assets/fonts/roboto-condensed-v19-latin-700.woff2';

import OpenSansRegularWoff2 from '../assets/fonts/open-sans-v23-latin-ext_latin-regular.woff2';
import OpenSans700Woff2 from '../assets/fonts/open-sans-v23-latin-ext_latin-700.woff2';

import BebasNeueRegularWoff2 from '../assets/fonts/bebas-neue-v2-latin-ext_latin-regular.woff2';

export const fontStyles = css`

  /* ROBOTO CONDENSED */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${RobotoCondensedRegularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${RobotoCondensed700Woff2}) format('woff2');
  }

  /* OPEN SANS */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${OpenSansRegularWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${OpenSans700Woff2}) format('woff2');
  }

  /* BEBAS NEUE */
  @font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${BebasNeueRegularWoff2}) format('woff2');
  }
`;
