import React, { lazy, Suspense } from "react";
import { Layout } from 'components/Layout/Layout';
import PropTypes from 'prop-types';
import {
  Navigate,
  Route,
  Routes as ReactRouterRoutes,
  useSearchParams,
} from 'react-router-dom';

import {
  aboutUrl,
  donateUrl,
  favouritesUrl,
  historyUrl,
  kidsUrl,
  literatureUrl,
  moviesUrl,
  privacyUrl,
  searchUrl,
  seriesUrl
} from './urls';

import { Home } from 'views/Home/Home';
import { PageLoader } from './PageLoader';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { SearchView } from 'views/Search/SearchView';

// lazy loading these components!
const About = lazy(() => import('views/About/About'));
const BookGroupView = lazy(() => import('views/Literature/BookGroupView'));
const BookView = lazy(() => import('views/Literature/BookView'));
const Donate = lazy(() => import('views/Donate/Donate'));
const Favourites = lazy(() => import('views/Favourites/Favourites'));
const History = lazy(() => import('views/History/History'));
const Kids = lazy(() => import('views/Kids/Kids'));
const Literature = lazy(() => import('views/Literature/Literature'));
const Movies = lazy(() => import('views/Movies/Movies'));
const NotFound = lazy(() => import('views/NotFound/NotFound'));
const Privacy = lazy(() => import('views/Privacy/Privacy'));
const SeriesView = lazy(() => import('views/Series/SeriesView/SeriesView'));
const ShowMovieView = lazy(() => import('views/Movies/ShowMovieView'));
const ShowSeriesView = lazy(() => import('views/Series/SeriesDetailsView/ShowSeriesView'));
const ShowSeriesViewFull = lazy(() => import('views/Series/SeriesDetailsView/ShowSeriesViewFull'));


export const Routes = ({ isLoading, error }) => {
  const [params] = useSearchParams();

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    // TODO: Add support for error handling
    return <div>There was a problem. {error}</div>;
  }

  if (params.get('path')) return <Navigate to={params.get('path')} />;

  return (
    <ErrorBoundary>
      <Suspense fallback={<PageLoader />}>
        <ReactRouterRoutes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={moviesUrl}>
              <Route index element={<Movies />} />
              <Route
                path={`${moviesUrl}/:movieSlug`}
                exact
                element={<ShowMovieView />}
              />
            </Route>
            <Route path={seriesUrl}>
              <Route index element={<SeriesView />} />
              <Route
                path={`${seriesUrl}/:seriesSlug`}
                exact
                element={<ShowSeriesView />}
              />
              <Route
                path={`${seriesUrl}/:seriesSlug/all`}
                exact
                element={<ShowSeriesViewFull />}
              />
              <Route
                path={`${seriesUrl}/:seriesSlug/:seasonSlug`}
                exact
                element={<ShowSeriesView />}
              />
            </Route>
            <Route path={kidsUrl} element={<Kids />} />
            <Route path={literatureUrl}>
              <Route index element={<Literature />} />
              <Route
                path={`${literatureUrl}/:bookGroup`}
                exact
                element={<BookGroupView />}
              />
              <Route
                path={`${literatureUrl}/:bookGroup/:bookCode`}
                exact
                element={<BookView />}
              />
            </Route>

            <Route path={aboutUrl} element={<About />} />
            <Route path={donateUrl} element={<Donate />} />
            <Route path={favouritesUrl} element={<Favourites />} />
            <Route path={historyUrl} element={<History />} />
            <Route path={privacyUrl} element={<Privacy />} />
            <Route path={searchUrl} exact element={<SearchView />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </ReactRouterRoutes>
      </Suspense>
    </ErrorBoundary>
  );
};

Routes.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.any,
};
