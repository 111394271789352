import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { Input } from 'components/Input/Input';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { cssThemeColor, pxToRem } from 'utils/theme';

const Container = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: 70px;
`;

const MobileSearchContainer = styled.div`
  width: 100%;
  padding: ${pxToRem(4)} ${pxToRem(24)} ${pxToRem(16)};
  background-color: ${cssThemeColor('darkGrey95')};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const MotionMobileSearchContainer = motion(MobileSearchContainer);

export const MobileSearch = ({ isVisible, value, onChange }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Container>
          <MotionMobileSearchContainer
            initial={{ translateY: '-100%' }}
            animate={{ translateY: '0%' }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            exit={{ translateY: '-100%' }}
          >
            <StyledInput
              placeholder="Hae sivustolta..."
              icon={<SearchIcon />}
              iconPlacement="right"
              type="text"
              value={value}
              onChange={onChange}
            />
          </MotionMobileSearchContainer>
        </Container>
      )}
    </AnimatePresence>
  );
};

MobileSearch.propTypes = {
  isVisible: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
