import { useCallback, useContext, useMemo } from 'react';
import { VideoPlayerContext } from 'components/VideoPlayer/VideoPlayerContext';
import {
  isFirstInPlaylist,
  isLastInPlaylist,
} from 'components/VideoPlayer/utils';

export const useVideoPlayer = () => {
  const context = useContext(VideoPlayerContext);
  if (context === undefined) {
    throw new Error('useVideoPlayer must be used within a VideoPlayerProvider');
  }

  const { state, dispatch } = context;

  const open = useCallback(
    ({ video, path, playlist }) => {
      dispatch({ type: 'open', video, path, playlist });
    },
    [dispatch]
  );

  const close = useCallback(() => {
    dispatch({ type: 'close' });
  }, [dispatch]);

  const next = useCallback(() => {
    dispatch({ type: 'next' });
  }, [dispatch]);

  const previous = useCallback(() => {
    dispatch({ type: 'previous' });
  }, [dispatch]);

  const isFirst = useMemo(() => {
    return isFirstInPlaylist(state.video, state.playlist);
  }, [state.video, state.playlist]);

  const isLast = useMemo(() => {
    return isLastInPlaylist(state.video, state.playlist);
  }, [state.video, state.playlist]);

  return {
    ...state,
    isFirst,
    isLast,
    open,
    close,
    next,
    previous,
  };
};
