/**
 * Calculate the preferred viewport width value to be used in a CSS clamp function.
 *
 * See https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/
 *
 * @param {number} breakpointStart The minimum breakpoint at which the fluid font size starts
 * @param {number} breakpointEnd The maximum breakpoint at which the fluid font size ends
 * @param {number} fontSizeStart The minumum font size
 * @param {number} fontSizeEnd The maximum font size
 * @returns The vw value for the preferred font size as a numeric value
 */
export const preferredViewportWidth = (
  breakpointStart,
  breakpointEnd,
  fontSizeStart,
  fontSizeEnd
) => (100 * (fontSizeEnd - fontSizeStart)) / (breakpointEnd - breakpointStart);

/**
 * Calculate the preferred rem value to be used in a CSS clamp function.
 *
 * See https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/
 *
 * @param {number} breakpointStart The minimum breakpoint at which the fluid font size starts
 * @param {number} breakpointEnd The maximum breakpoint at which the fluid font size ends
 * @param {number} fontSizeStart The minumum font size
 * @param {number} fontSizeEnd The maximum font size
 * @returns The rem value for the preferred font size as a numeric value
 */
export const preferredRem = (
  breakpointStart,
  breakpointEnd,
  fontSizeStart,
  fontSizeEnd
) =>
  (breakpointStart * fontSizeEnd - breakpointEnd * fontSizeStart) /
  (breakpointStart - breakpointEnd) /
  16;
