import { VIDEO_TYPE_VIMEO, VIDEO_TYPE_YOUTUBE } from 'constants/constants';
import { useContext } from 'react';
import { isValue } from 'utils/function';
import { CookieConsentContext } from './context';

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);

  if (context === undefined) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider'
    );
  }
  const { consentOptions, setConsentOptions, modalOpen, setModalOpen } =
    context;

  return {
    isConsentSet: isValue(consentOptions),
    allowVimeo: Boolean(consentOptions?.[VIDEO_TYPE_VIMEO]),
    allowYoutube: Boolean(consentOptions?.[VIDEO_TYPE_YOUTUBE]),
    consentOptions,
    setConsentOptions,
    modalOpen,
    setModalOpen,
  };
};
