import { Accordion } from 'components/Accordion/Accordion';
import { Button } from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Switch } from 'components/Switch/Switch';
import { ThinScrollbarContainer } from 'components/ThinScrollbarContainer/ThinScrollbarContainer';
import { VIDEO_TYPE_VIMEO, VIDEO_TYPE_YOUTUBE } from 'constants/constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeTypography } from 'utils/theme';
import { useCookieConsent } from './hooks';

const Content = styled.div`
  padding: 16px;

  @media ${media.md} {
    padding: 24px;
  }
`;

const OptionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const OptionHeaderTechnical = styled.span`
  font-size: 0.875rem;
`;

const OptionTitle = styled.h3`
  font-family: ${cssThemeTypography('heading')};
  font-weight: 300;
  font-size: 1.125rem;
  margin: 0;
  flex: 1;
`;

const Description = styled.p`
  font-size: 0.875rem;
  margin: 0 0 16px;

  @media ${media.md} {
    margin: 0 0 24px;
  }
`;

const OptionDescription = styled.p`
  font-size: 0.875rem;
  padding-left: 40px;
  margin: 16px 0 24px;
`;

export const CookieConsentModal = ({ isVisible, onDismiss }) => {
  const { isConsentSet, consentOptions, setConsentOptions } =
    useCookieConsent();

  const [selectedOptions, setSelectedOptions] = useState(
    isConsentSet ? consentOptions : { technical: true }
  );

  const handleAccept = () => {
    setConsentOptions(selectedOptions);
    onDismiss();
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      setSelectedOptions((options) => ({ ...options, [value]: true }));
    } else {
      setSelectedOptions((options) => {
        const newOptions = { ...options };
        delete newOptions[value];
        return newOptions;
      });
    }
  };

  const renderOptionHeader = ({ id }) => {
    const headerTitles = {
      technical: 'Välttämättömät evästeet',
      [VIDEO_TYPE_VIMEO]: 'Vimeo-palvelun evästeet',
      [VIDEO_TYPE_YOUTUBE]: 'YouTube-palvelun evästeet',
    };
    return (
      <OptionHeader>
        <OptionTitle>{headerTitles[id]}</OptionTitle>
        {id === 'technical' ? (
          <OptionHeaderTechnical>Aina aktiivinen</OptionHeaderTechnical>
        ) : (
          <Switch
            value={id}
            disabled={id === 'technical'}
            checked={Boolean(selectedOptions?.[id])}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </OptionHeader>
    );
  };

  const renderOptionContent = ({ id }) => {
    const descriptions = {
      technical:
        'Nämä evästeet ovat välttämättömiä palvelujemme toiminnalle, eikä niitä voida kytkeä pois päältä. Ne asetetaan yleensä vastauksena palveluiden pyyntöä vastaaviin toimiisi, joihin lukeutuvat tietosuoja-asetusten määrittäminen, sisällön käyttö, katseluhistoriasi sekä suosikkilistasi tallentaminen. Voit asettaa selaimesi estämään nämä evästeet tai varoittamaan sinua niistä, mutta osa sivustosta ei silloin toimi.',
      vimeo:
        'Käytämme osaan sisältöä Vimeo-palvelua videoiden toistamiseen. Sallimalla Vimeon evästeet mahdollistat Vimeo-palvelun käyttämisen. Tähän sisältyvät Vimeo-pohjaisten videoiden katselu ja järjestelmän keräämä statistiikka, joka Vimeon ilmoituksen mukaan toimii sopusoinnussa Euroopan unionin yleisen tietosuoja-asetuksen kanssa. Ilman näiden evästeiden hyväksyntää et voi katsoa sisältöä, jonka palveluntarjoaja on Vimeo. Käytämme statistiikkaa ohjemistotarjontamme parantamiseen.',
      youtube:
        'Käytämme osaan sisältöä YouTube-palvelua videoiden toistamiseen. Sallimalla YouTuben evästeet mahdollistat YouTube-palvelun käyttämisen. Tällöin järjestelmä toimii videoiden katselun, analytiikan ja kolmannen osapuolen mainoksien suhteen samalla tavalla kuin jos katselisit videoita suoraan YouTubessa. Ilman näiden evästeiden hyväksyntää et voi katsoa sisältöä, jonka palveluntarjoaja on YouTube.',
    };
    return <OptionDescription>{descriptions[id]}</OptionDescription>;
  };

  return (
    <Modal variant="fixedWide" isVisible={isVisible} onDismiss={onDismiss}>
      <ModalHeader onDismiss={onDismiss}>Evästeiden asetukset</ModalHeader>
      <ThinScrollbarContainer>
        <Content>
          <Description>
            Kunnioitamme tietosuojaasi. Voit halutessasi estää tietyntyyppiset
            evästeet, mutta niiden estäminen vaikuttaa sivuston toimintaan. Voit
            muuttaa asetuksiasi milloin tahansa.
          </Description>
          <Accordion
            items={[
              { id: 'technical' },
              { id: VIDEO_TYPE_VIMEO },
              { id: VIDEO_TYPE_YOUTUBE },
            ]}
            renderHeader={renderOptionHeader}
            renderContent={renderOptionContent}
          />
        </Content>
      </ThinScrollbarContainer>
      <ModalFooter>
        <Button onClick={handleAccept}>Tallenna valinnat</Button>
      </ModalFooter>
    </Modal>
  );
};

CookieConsentModal.propTypes = {
  isVisible: PropTypes.bool,
  onDismiss: PropTypes.func,
};
