import React from 'react';

const Requirements = () => {
  return (
    <>
      <p>
        Järjestelmämme toimii kännyköissä, tableteissa, kannettavissa, pöytätietokoneissa ja älytelevisioissa, joissa on riittävän nopea internet-yhteys sekä yksi seuraavista selaimista, jotta sivustomme toimii oikein. Selaimen perässä on ohjelmistoversion numero, joka on järjestelmämme minimivaatimus. Sitä vanhemmissa versioissa järjestelmä ei toimi oikein. Suosittelemme Firefoxia ja Chromium-pohjaisia selaimia (Chrome, Edge, Opera).
      </p>
      <ul>
        <li>Android Browser 119</li>
        <li>Chrome 88</li>
        <li>Chrome (Android) 119</li>
        <li>Edge 88</li>
        <li>Firefox 89</li>
        <li>Firefox (Android) 119</li>
        <li>Opera 74</li>
        <li>Opera Mobile 73</li>
        <li>Safari 15 ja MacOS Big Sur tai uudempi</li>
        <li>Safari (iOS) 15</li>
        <li>Samsung internet 15</li>
      </ul>
      <p>
        Emme tue Internet Explorer -selainta. Jos sinulla ei ole sopivaa selainta, suosittelemme lataamaan <a href="https://www.google.com/intl/fi_fi/chrome/">Chromen</a> tai <a href="https://www.mozilla.org/fi/firefox/new/">Firefoxin</a> uusimman version tai päivittämään selaimesi, mikäli se on yksi tukemistamme. Käytännössä selain, joka on julkaistu ennen vuotta 2021 on teknisesti jo liian vanha, sillä käytämme uusinta teknologiaa palvelun mahdollistamiseksi.
      </p>
    </>
  )
};

export default Requirements;