import { ReactComponent as Logo } from 'assets/hope-logo-icon.svg';
import React from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeTypography, pxToRem } from 'utils/theme';

const Root = styled.div`
  display: flex;
  gap: 0.2rem;
  font-size: 0.9rem;
  margin-top: 1.6rem;
  font-family: ${cssThemeTypography('heading_uppercase')};
`;

const LogoContainer = styled.div`
  flex: 0 0 ${pxToRem(24)};
  margin: 0 auto;
  width: ${pxToRem(24)};

  @media ${media.md} {
    margin: 0;
  }
`;

const HopeOriginalText = styled.span`
  flex: 1;
`;

export function HopeBanner() {
  return (
    <Root>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <HopeOriginalText>alkuperäistuotanto</HopeOriginalText>
    </Root>
  );
}
