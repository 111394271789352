import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'root/theme';
import styled, { css } from 'styled-components';
import { cssThemeColor } from 'utils/theme';

const TRANSITION_DURATION = '200ms';
const TRANSITION_TIMING_FUNCTION = 'cubic-bezier(.4, .4, .2, 1)';

const colorsConfig = {
  borderChecked: theme.colors.hopeYellow,
  borderCheckedDisabled: theme.colors.hopeYellow10,
  borderUnchecked: theme.colors.white10,
  borderUncheckedDisabled: theme.colors.white10,
  bgChecked: theme.colors.hopeYellow,
  bgCheckedHover: theme.colors.hopeYellow90,
  bgCheckedActive: theme.colors.hopeYellow80,
  bgCheckedDisabled: theme.colors.hopeYellow50,
  bgUnchecked: theme.colors.white40,
  bgUncheckedHover: theme.colors.white30,
  bgUncheckedActive: theme.colors.white20,
  bgUncheckedDisabled: theme.colors.white10,
};

const Root = styled.div`
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  width: 36px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  transition: background-color ${TRANSITION_DURATION}
      ${TRANSITION_TIMING_FUNCTION},
    border-color ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION};

  border-color: ${({ checked, disabled }) => {
    if (checked) {
      return disabled
        ? colorsConfig.borderCheckedDisabled
        : colorsConfig.borderChecked;
    }

    return disabled
      ? colorsConfig.borderUncheckedDisabled
      : colorsConfig.borderUnchecked;
  }};

  background-color: ${({ checked, disabled }) => {
    if (checked) {
      return disabled ? colorsConfig.bgCheckedDisabled : colorsConfig.bgChecked;
    }

    return disabled
      ? colorsConfig.bgUncheckedDisabled
      : colorsConfig.bgUnchecked;
  }};

  ${({ disabled, checked }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${checked
          ? colorsConfig.bgCheckedHover
          : colorsConfig.bgUncheckedHover};
      }

      &:active {
        background-color: ${checked
          ? colorsConfig.bgCheckedActive
          : colorsConfig.bgUncheckedActive};
      }
    `}

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: ${({ 'aria-checked': checked }) => (checked ? '50%' : '1px')};
    bottom: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${cssThemeColor('darkGrey80')};
    transition: left ${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION};
  }
`;

const FocusIndicator = styled.div`
  position: absolute;
  inset: -1px;
  z-index: 1;
  border-radius: 20px;
`;

const Input = styled.input`
  margin: 0;
  opacity: 0;
  outline-color: transparent;
  position: absolute;
  inset: 0;
  z-index: 2;
  width: 100%;
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Switch = ({
  checked = false,
  disabled = false,
  name,
  ...rest
}) => (
  <Root
    checked={checked}
    disabled={disabled}
    aria-checked={checked}
    aria-disabled={disabled}
  >
    <Input
      {...rest}
      checked={checked}
      disabled={disabled}
      name={name}
      type="checkbox"
    />
    <FocusIndicator />
  </Root>
);

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};
