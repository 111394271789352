import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

export const ResponsiveImage = ({
  sources = [],
  className,
  defaultSrc,
  title,
  svgFile,
}) => (
  <picture>
    {sources.map(({ media, src }, index) => (
      <Fragment key={`${src}-${index}`}>
        {svgFile && (
            <source key={`${src}-${index}-s`} media={media} srcSet={`${src}`} />
        )}
        {!svgFile && (
            <source key={`${src}-${index}-w`} media={media} srcSet={`${src}.webp`} />
        )}
      </Fragment>
    ))}
    {svgFile && (
      <img
        className={className}
        src={`${defaultSrc}`}
        alt={title}
        width="100%"
        height="auto"
      />
    )}
    {!svgFile && (
      <img
        className={className}
        src={`${defaultSrc}.webp`}
        alt={title}
        width="100%"
        height="auto"
      />
    )}
  </picture>
);

ResponsiveImage.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string,
      src: PropTypes.string,
    })
  ),
  defaultSrc: PropTypes.string,
  title: PropTypes.string,
};