import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import {
  donateUrl,
  moviesUrl,
  literatureUrl,
  seriesUrl,
  favouritesUrl,
  kidsUrl
} from 'root/urls';
import styled from 'styled-components';
import { cssThemeColor } from 'utils/theme';
import { MobileNavigationLink } from './MobileNavigationLink';

const Container = styled.div`
  width: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 10;
  top: 70px;
`;

const MobileNav = styled.nav`
  width: 100%;
  background-color: ${cssThemeColor('darkGrey95')};
`;

const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0 24px 8px;
  border-top: 1px solid ${cssThemeColor('white10')};
`;

const NavigationListItem = styled.li`
  & + & {
    border-top: 1px solid ${cssThemeColor('white10')};
  }
`;

const MotionMobileNav = motion(MobileNav);

export const MobileNavigationList = ({ isVisible, onNavigate }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Container>
          <MotionMobileNav
            initial={{ translateY: '-100%' }}
            animate={{ translateY: '0%' }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            exit={{ translateY: '-100%' }}
          >
            <NavigationList>
              <NavigationListItem>
                <MobileNavigationLink to={seriesUrl} onClick={onNavigate}>
                  Sarjat
                </MobileNavigationLink>
              </NavigationListItem>
              <NavigationListItem>
                <MobileNavigationLink to={moviesUrl} onClick={onNavigate}>
                  Elokuvat
                </MobileNavigationLink>
              </NavigationListItem>
              <NavigationListItem>
                <MobileNavigationLink to={kidsUrl} onClick={onNavigate}>
                  Lastenohjelmat
                </MobileNavigationLink>
              </NavigationListItem>
              <NavigationListItem>
                <MobileNavigationLink to={literatureUrl} onClick={onNavigate}>
                  Kirjallisuus
                </MobileNavigationLink>
              </NavigationListItem>
              <NavigationListItem>
                <MobileNavigationLink to={donateUrl} onClick={onNavigate}>
                  Lahjoita
                </MobileNavigationLink>
              </NavigationListItem>
              <NavigationListItem>
                <MobileNavigationLink to={favouritesUrl} onClick={onNavigate}>
                  Suosikit
                </MobileNavigationLink>
              </NavigationListItem>
            </NavigationList>
          </MotionMobileNav>
        </Container>
      )}
    </AnimatePresence>
  );
};

MobileNavigationList.propTypes = {
  isVisible: PropTypes.bool,
  onNavigate: PropTypes.func,
};
