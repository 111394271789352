import { ResponsiveImage } from 'components/ResponsiveImage/ResponsiveImage';
import { PAGE_IMAGE_ID } from 'constants/constants';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { media } from 'utils/media';

const StyledResponsiveImage = styled(ResponsiveImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;

export const PageImage = ({ portrait, landscape, title }) => {
  const [container, setContainer] = React.useState();

  React.useEffect(() => {
    const pageImageElement = document.getElementById(PAGE_IMAGE_ID);
    if (pageImageElement) {
      setContainer(pageImageElement);
    }
  }, []);

  if (container) {
    return ReactDOM.createPortal(
      <StyledResponsiveImage
        sources={[
          { media: media.max.sm, src: portrait },
          { media: media.md, src: landscape },
        ]}
        defaultSrc={landscape}
        title={title}
      />,
      container
    );
  }

  return null;
};

PageImage.propType = {
  portrait: PropTypes.bool,
  landscape: PropTypes.bool,
  title: PropTypes.string,
};
