import { AnimatedCard } from 'components/AnimatedCard/AnimatedCard';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardImage } from './CardImage';
import { ExpandedCard } from './ExpandedCard';

const NoWrap = styled.span`
  white-space: nowrap;
`;

export const PosterCard = ({
  id,
  url,
  imageUrl,
  title,
  subtitle,
  info,
  produced,
  producer,
  suitableFor,
  videoFormat,
  keywords,
  expand,
  expandOrigin,
  aspectRatio,
  isLazy,
  sticker
}) => {
  const Copyright = () => {
    if (produced && producer) {
      return (
        <>
          <NoWrap>
            &copy; {produced}
          </NoWrap>
          {" "}
          {producer}
        </>
      );
    }

    return null;
  };

  return expand ? (
    <AnimatedCard
      content={
        <ExpandedCard
          id={id}
          url={url}
          imageUrl={imageUrl}
          title={title}
          subtitle={subtitle}
          sticker={sticker}
          info={info}
          copyright={<Copyright />}
          produced={produced}
          producer={producer}
          suitableFor={suitableFor}
          videoFormat={videoFormat}
          keywords={keywords}
          expandOrigin={expandOrigin}
          isLazy={isLazy}
        />
      }
      expandOrigin={expandOrigin}
    >
      <CardImage
        url={url}
        imageUrl={imageUrl}
        title={title}
        subtitle={subtitle}
        sticker={sticker}
        aspectRatio={aspectRatio}
        isLazy={isLazy}
      />
    </AnimatedCard>
  ) : (
    <CardImage
      url={url}
      imageUrl={imageUrl}
      title={title}
      subtitle={subtitle}
      copyright={<Copyright />}
      info={info}
      sticker={sticker}
      aspectRatio={aspectRatio}
      showDetailsOnHover
      isLazy={isLazy}
    />
  );
};

PosterCard.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  info: PropTypes.string,
  sticker: PropTypes.string,
  episodeCount: PropTypes.number,
  produced: PropTypes.string,
  producer: PropTypes.string,
  suitableFor: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  expand: PropTypes.bool,
  expandOrigin: PropTypes.oneOf(['left', 'right', 'center']),
  aspectRatio: PropTypes.number,
};
