import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as BanIcon } from 'assets/icons/ban.svg';
import { ReactComponent as PlayTriangle } from 'assets/icons/icon-play-triangle.svg';
import { ReactComponent as PlayCircle } from 'assets/icons/icon-play-circle.svg';
import { Tag } from 'components/Tag/Tag';
import { VIDEO_TYPE_NONE, BORDER_RADIUS, BORDER_WIDTH } from 'constants/constants';
import { cssThemeColor, cssThemeTypography, pxToRem } from 'utils/theme';
import { RedSticker } from 'components/InfoStyles/InfoStyles';
import { isReleasedDateStillValid } from 'utils/utils';
import { useCookieConsent } from 'components/CookieConsent/hooks';

const Card = styled.article`
   display: grid;
   grid-auto-rows: auto auto 1fr auto;
   gap: 8px;
`;

const PlayIndicator = styled.span`
   height: 5px;
   width: 100% - 2*${BORDER_RADIUS}px);
   position: absolute;
   left: ${BORDER_RADIUS}px;
   right: ${BORDER_RADIUS}px;
   bottom: ${BORDER_RADIUS}px;
   background-color: ${cssThemeColor('hopeBlue70')};
   z-index: 1;

   &:before {
      content: '';
      display: block;
      height: 5px;
      width: ${({ $percentPlayed }) => $percentPlayed}%;
      background-color: ${cssThemeColor('hopeYellow')};
   }
`;

const ImageContainer = styled.div`
   position: relative;
   cursor: ${({ $allowVideoPlay }) => ($allowVideoPlay ? 'pointer' : 'default')};
   box-shadow: 0 12px 8px -8px #080808;
   aspect-ratio: 16 / 9;

   &:focus,
   &:hover {
      ${RedSticker} {
         display: none;
      }
      ${PlayIndicator} {
         display: none;
      }
   }
`;

const StaticImageContainer = styled.div`
   position: relative;
`;

const Image = styled.img`
   width: 100%;
   height: 100%;
   display: block;
   object-fit: cover;
   border-radius: ${BORDER_RADIUS}px;
`;

const Header = styled.header`
   display: flex;
   align-items: top;
   padding: 0 8px;
   gap: 8px;
`;

const Title = styled.h4`
   margin: 0;
   font-family: ${cssThemeTypography('heading')};
   font-size: 1rem;
   font-weight: 400;

   &::first-letter {
      text-transform: uppercase;
   }
`;

const Content = styled.div`
   font-size: ${pxToRem(14)};
   color: ${cssThemeColor('faintGrey')};
   padding: 0 8px;
   min-height: 1rem;

   & > p {
      margin: 6px 0;
   }

   & > p:nth-child(1) {
      margin-top: 0;
   }

   & > p:nth-last-child(1) {
      margin-bottom: 0;
   }
`;

const Footer = styled.footer`
   padding: 0 8px;
   display: flex;
   justify-content: ${({ $refTag }) =>
      $refTag ? 'space-between' : 'flex-end'};
   align-items: center;
`;

const Duration = styled.time`
   color: ${cssThemeColor('faintGrey')};
   font-size: 0.75rem;
`;

const PlayContainer = styled.div`
   position: absolute;
   inset: 0;
   z-index: 2;
   background: transparent;
   display: flex;
   justify-content: center;
   align-items: center;
   color: ${cssThemeColor('white80')};
   transition: background 0.2s ease-in-out;
   
   &:focus,
   &:hover {
      border: ${BORDER_WIDTH}px solid ${cssThemeColor('white80')};
      border-radius: ${BORDER_RADIUS}px;
      background: linear-gradient(
         rgba(0, 0, 0, 0.4),
         rgba(0, 0, 0, 0.5),
         rgba(0, 0, 0, 0.6)
         );
      & > div {
         opacity: 1;
      }

      svg,
      p {
         opacity: 1;
      }
   }

   svg,
   p {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
   }

   svg {
      flex-shrink: 0;
      color: ${cssThemeColor('hopeYellow')};
      width: 48px;
      height: 48px;
   }
`;

const BanInfo = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   p {
      color: ${cssThemeColor('hopeYellow')};
      font-size: 0.9375rem;
      font-weight: bold;
      text-align: center;
      text-shadow: 1px 1px black;
      margin: 8px 0 0;
      padding: 0 32px;
   }
`;

const PlayHead = styled.div`
   opacity: 0;
   position: relative;
   width: 100%;
   height: 100%;
`;

const Triangle = styled(PlayTriangle)`
   bottom: 40%;
   right: 40%;
   width: 3rem;
   position: absolute;
   z-index: 21;
   display: block;

   polygon {
      fill: ${cssThemeColor('white')};
   }
`;

const Circle = styled(PlayCircle)`
   bottom: 40%;
   right: 40%;
   width: 3rem;
   position: absolute;
   z-index: 20;
   display: block;

   circle {
      stroke: ${cssThemeColor('hopeYellow')};
   }
`;

const getBanInfoVideoType = (videoType) => {
   if (videoType === 'vimeo') {
      return 'Vimeo-palvelun';
   }
   if (videoType === 'youtube') {
      return 'YouTube-palvelun';
   }
   return '';
};

const getDurationString = (duration) => {
   const parts = duration.split(':');
   if (parts.length === 3) {
      return `PT${Number(parts[0])}H${Number(parts[1])}M${Number(parts[2])}S`;
   }
   return `PT0H${Number(parts[0])}M${Number(parts[1])}S`;
};

export function VideoPlayerCard({
   titleOnly,
   videoTag,
   videoType,
   imageUrl,
   episodeNumber,
   title,
   details,
   refTag,
   duration,
   consentOptions,
   percentPlayed,
   onPlay,
   isLazy,
   releasedAt
}) {
   const allowVideoPlay = videoTag && consentOptions[videoType];
   const { setModalOpen } = useCookieConsent();

   const handleClickPlayVideo = () => {
      if (allowVideoPlay) {
         onPlay();
      }
      else {
         setModalOpen(true);
      }
   };

   if (
      !videoType ||
      videoType === VIDEO_TYPE_NONE ||
      !videoTag ||
      videoTag < 1
   ) {
      return (
         <Card>
            <StaticImageContainer>
               {isLazy && <Image loading="lazy" src="/images/misc/empty-episode.webp" alt="empty" />}
               {!isLazy && <Image src="/images/misc/empty-episode.webp" alt="empty" />}
            </StaticImageContainer>
            <Header />
            <Content />
            <Footer />
         </Card>
      );
   };

   let showDetails = [];
   if (details) {
      showDetails = details.split('<p>').map((d, i) => <p key={`${videoTag}${i}`}>{d}</p>);
   }

   let sticker = null; if (isReleasedDateStillValid(releasedAt)) sticker = "uusi";

   return (
      <Card>
         <ImageContainer
            $allowVideoPlay={allowVideoPlay}
            onClick={() => handleClickPlayVideo()}
         >
            {imageUrl ? (
               <Image
                  loading="lazy"
                  src={`${imageUrl}.webp`}
                  alt={title}
                  width="100%"
                  height="auto"
               />
            ) : (
               <Image
                  loading="lazy"
                  src="/images/misc/empty-episode.webp"
                  alt="empty"
                  width="100%"
                  height="auto"
               />
            )}
            <PlayContainer>
               {allowVideoPlay ? (
                  <PlayHead>
                     <Triangle>
                        <PlayTriangle />
                     </Triangle>
                     <Circle>
                        <PlayCircle />
                     </Circle>
                  </PlayHead>
               ) : (
                  <BanInfo>
                     <BanIcon />
                     <p>{`Et ole hyväksynyt ${getBanInfoVideoType(
                        videoType
                     )} evästeitä. Avaa asetukset klikkaamalla videota.`}</p>
                  </BanInfo>
               )}
            </PlayContainer>
            {sticker && <RedSticker $videocard>{sticker}</RedSticker>}
            {Boolean(percentPlayed) && (
               <PlayIndicator $percentPlayed={percentPlayed} />
            )}
         </ImageContainer>

         <Header>
            {!titleOnly && episodeNumber && <Tag size="large">{episodeNumber}</Tag>}
            <Title>{title}</Title>
         </Header>

         {!titleOnly && (
            <>
               <Content>
                  {showDetails}
               </Content>

               <Footer $refTag={refTag}>
                  {refTag && (
                     <Tag $variant="outlineFaintGrey" $fontVariant="body" $size="small">
                        {refTag}
                     </Tag>
                  )}
                  <Duration dateTime={getDurationString(duration)}>
                     {duration}
                  </Duration>
               </Footer>
            </>
         )}
      </Card>
   );
}

VideoPlayerCard.propTypes = {
   titleOnly: PropTypes.bool,
   consentOptions: PropTypes.object,
   percentPlayed: PropTypes.number,
   onPlay: PropTypes.func,
   videoTag: PropTypes.string,
   videoType: PropTypes.string,
   imageUrl: PropTypes.string,
   sticker: PropTypes.string,
   episodeNumber: PropTypes.string,
   title: PropTypes.string,
   details: PropTypes.string,
   refTag: PropTypes.string,
   duration: PropTypes.string,
   isLazy: PropTypes.bool
};
