import styled from 'styled-components';
import { CONTENT_AREA_MAXWIDTH } from 'constants/constants';
import { pxToRem } from 'utils/theme';
import { media } from 'utils/media';

export const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: 0 ${pxToRem(24)};
  max-width: ${pxToRem(CONTENT_AREA_MAXWIDTH)};

  @media ${media.sm} {
    padding: 0 ${pxToRem(40)};
  }
`;

export const PageWrapperTight = styled(PageWrapper)`
  max-width: ${pxToRem(CONTENT_AREA_MAXWIDTH * 0.7)};
`;

export const PageWrapperBlock = styled(PageWrapper)`
  min-height: 23rem;
`;
