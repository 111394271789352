import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'root/theme';
import styled from 'styled-components';
import { cssThemeColor } from 'utils/theme';

const buttonSize = {
  small: 16,
  medium: 20,
  large: 24,
};

const buttonVariant = {
  white: {
    color: theme.colors.white,
    hoverColor: theme.colors.white70,
  },
  hopeYellow: {
    color: theme.colors.hopeYellow,
    hoverColor: theme.colors.hopeYellow80,
  },
};

const BUTTON_PADDING = 4;

const Button = styled.button`
  background: none;
  border: none;
  padding: ${BUTTON_PADDING}px;
  border-radius: 4px;

  width: ${({ $size }) => buttonSize[$size] + BUTTON_PADDING * 2}px;
  height: ${({ $size }) => buttonSize[$size] + BUTTON_PADDING * 2}px;

  color: ${({ $variant }) => buttonVariant[$variant].color};

  cursor: pointer;

  &:hover {
    color: ${({ $variant }) => buttonVariant[$variant].hoverColor};
  }

  &:active,
  &:focus {
    outline-color: transparent;
    box-shadow: 0 0 0 2px ${cssThemeColor('hopeYellow50')};
  }

  &:disabled {
    color: ${cssThemeColor('lightGrey80')};
    cursor: not-allowed;
  }
`;

export const IconButton = ({
  title,
  icon,
  type = 'button',
  size = 'large',
  variant = 'white',
  ...rest
}) => {
  return (
    <Button type={type} $size={size} $variant={variant} title={title} {...rest}>
      {icon}
    </Button>
  );
};

IconButton.porpTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['white', 'hopeYellow']),
};
