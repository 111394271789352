import React from 'react';
import { getNextPlaylistItem, getPreviousPlaylistItem } from './utils';

const defaultState = {
  isVisible: false,
};

export const VideoPlayerContext = React.createContext(defaultState);

function videoPlayerReducer(state, action) {
  switch (action.type) {
    case 'open': {
      return {
        ...state,
        isVisible: true,
        video: action.video,
        path: action.path,
        playlist: action.playlist,
      };
    }
    case 'close': {
      return defaultState;
    }
    case 'next': {
      const nextVideo = getNextPlaylistItem(state.video, state.playlist);
      if (!nextVideo) {
        return state;
      }
      return {
        ...state,
        video: nextVideo,
        path: [...state.path.slice(0, -1), nextVideo.id],
      };
    }
    case 'previous': {
      const previousVideo = getPreviousPlaylistItem(
        state.video,
        state.playlist
      );
      if (!previousVideo) {
        return state;
      }
      return {
        ...state,
        video: previousVideo,
        path: [...state.path.slice(0, -1), previousVideo.id],
      };
    }
    default: {
      return state;
    }
  }
}

export const VideoPlayerProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(videoPlayerReducer, {
    isVisible: false,
  });
  const value = { state, dispatch };
  return (
    <VideoPlayerContext.Provider value={value}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerContextValue = () => {};
