import React from 'react';
import PropTypes from 'prop-types';
import PosterGrid from 'components/PosterGrid/PosterGrid';
import { ScrollList } from 'components/ScrollList/ScrollList';
import { PosterCard } from 'components/PosterCard/PosterCard';
import { getStickerText } from 'utils/utils';


export function SeriesPosterGrid({
  scroll,
  mini,
  filters,
  videos
}) {


  if (scroll) {
    return (
      <ScrollList
        items={videos}
        renderItem={(video) => <PosterCard {...video} aspectRatio={150} sticker={getStickerText( video.releasedAt, video.updatedAt, video.id )} />}
      />
    );
  }

  return (
    <PosterGrid
      videos={videos}
      cardSizeMultiplier={!mini ? 0.75 : undefined}
      filters={filters}
      aspectRatio={150}
      mini={mini}
      isLazy={mini}
    />
  );
}

SeriesPosterGrid.propTypes = {
  scroll: PropTypes.bool,
  mini: PropTypes.bool,
  seriesFilter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  seasonFilter: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  filters: PropTypes.node,
};
