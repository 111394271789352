import { useCallback, useEffect, useState } from 'react';
import { getStorageItem, setStorageItem } from 'utils/storage';
import { useAppData } from 'hooks/useAppData';
import { STORAGE_KEY, HISTORY_SIZE } from 'constants/constants';


export const useViewingHistory = () => {
  const { getEpisodeByPath, getSeasonByPath, getMovie } = useAppData();

  const [viewingHistory, setViewingHistory] = useState(
    getStorageItem(STORAGE_KEY, [])
  );

  useEffect(() => {
    setStorageItem(STORAGE_KEY, viewingHistory);
  }, [viewingHistory]);

  const addViewing = useCallback(
    ({ videoId, type, path, seconds, duration }) => {
      const newState = [
        { videoId, type, path, seconds, duration },
        ...viewingHistory.filter(
          (viewing) => viewing.videoId !== videoId && viewing.type === type
        ),
      ];
      setViewingHistory(newState.slice(0, HISTORY_SIZE));
    },
    [viewingHistory]
  );

  const getViewing = useCallback(
    ({ type, videoId }) =>
      viewingHistory.find((v) => v.type === type && v.videoId === videoId),
    [viewingHistory]
  );

  const clearHistory = useCallback(() => {
    setViewingHistory([]);
  }, []);

  const getAugmentedViewingHistory = useCallback(
    (limit = 4) =>
      viewingHistory.slice(0, limit).reduce((acc, viewing) => {
        const { path, seconds, duration } = viewing;
        if (path[0] === 'series') {
          const season = getSeasonByPath(path);
          const episode = getEpisodeByPath(path);
          if (episode) {
            return [
              ...acc,
              {
                video: episode,
                path,
                playlist: season.episodes,
                seconds,
                duration,
              },
            ];
          }
        }
        if (path[0] === 'movie') {
          const movie = getMovie(path[1]);
          if (movie) {
            return [...acc, { video: movie, path, seconds, duration }];
          }
        }
        return acc;
      }, []),
    [viewingHistory, getSeasonByPath, getEpisodeByPath, getMovie]
  );

  return {
    viewingHistory,
    getAugmentedViewingHistory,
    getViewing,
    addViewing,
    clearHistory,
  };
};
