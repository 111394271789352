import { ContentContainer } from 'components/ContentContainer/ContentContainer';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from 'utils/media';
import { cssThemeColor, cssThemeTypography } from 'utils/theme';
import { HopeBanner } from "components/HopeBanner/HopeBanner";
import {
  Details,
  fluidFontSize,
  textShadow,
  Title,
  SubTitle,
  TitleImage
} from 'components/InfoStyles/InfoStyles';

const HeroContainer = styled(ContentContainer)`
  margin-bottom: 64px;
  padding: 0;

  @media ${media.md} {
    margin-bottom: 96px;
  }
`;

const Header = styled.header`
  position: relative;
  margin-top: 96px;

  @media ${media.md} {
    margin-top: 180px;
  }
`;

const Kicker = styled.p`
  ${textShadow}

  text-align: ${({ $align }) => $align};
  font-family: ${cssThemeTypography('heading')};
  ${fluidFontSize(16, 24)}
  color: ${cssThemeColor('hopeYellow')};
  margin: 0;
  position: absolute;
  top: -8px;
  left: ${({ $align }) => ($align === 'center' ? '50%' : 'auto')};
  transform: translateY(-100%)
    translateX(${({ $align }) => ($align === 'center' ? '-50%' : '0px')});

  @media ${media.md} {
    top: -16px;
  }
`;

const Content = styled.div`
  margin-top: 48px;

  @media ${media.md} {
    margin-top: 64px;
  }
`;

export const Hero = ({ title,
   subtitle,
   details,
   kicker,
   align = 'left',
   isHopeProduction = false,
   heroImageUrlTitleLogo = null,
   children }) => {

   return (
      <HeroContainer as="article">
      <Header>
         {kicker && <Kicker $align={align}>{kicker}</Kicker>}
         {heroImageUrlTitleLogo &&
            <>
               <TitleImage image={heroImageUrlTitleLogo} alt={`${title}`} />
               <Details>{details}</Details>
            </>
         }
         {!heroImageUrlTitleLogo &&
            <>
               <Title $align={align}>{title}</Title>
               {subtitle && <SubTitle $align={align}>{subtitle}</SubTitle>}
               <Details>{details}</Details>
            </>
         }
         {isHopeProduction && <HopeBanner />}
      </Header>
      <Content>{children}</Content>
      </HeroContainer>
   );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  kicker: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center']),
};
